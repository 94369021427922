import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PermissionService } from "src/app/core/service/permission.service";
import { environment } from "src/environments/environment";
import { RouteInfo } from "./sidebar.metadata";
@Injectable({
  providedIn: "root",
})
export class AllRoutesService {
  constructor(public permissionService: PermissionService, private httpClient: HttpClient) { }
  private readonly API_URL = environment.apiUrl;

  getSearchFromApi(text: string): any {
    return this.httpClient.get(`${this.API_URL}/v1/utility/get/text?text=${text}`);
  }

  getUserMenu(): any {
    return this.httpClient.get(`${this.API_URL}/users/getmenu/${localStorage.getItem('userId')}`);
  }

  getUserFavorite(): any {
    return this.httpClient.get(`${this.API_URL}/favoritemenulink/get/${localStorage.getItem('userId')}`);
  }

  addToFavorite(claimKey: string) {
    var a = {
      "claimKey": claimKey,
      "userId": localStorage.getItem('userId')
    }
    return this.httpClient.post<any>(this.API_URL + '/favoritemenulink/create', a);
  }

  removeFromFavorite(claimKey: string): any {
    return this.httpClient.delete(`${this.API_URL}/favoritemenulink/delete/${claimKey},${localStorage.getItem('userId')}`);
  }

  getroutes(): any {
    return [
      //dashboard
      {
        path: "",
        title: "MENUITEMS.HOME.TEXT",
        moduleName: "dashboard",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "home.svg",
        role: ["All"],
        displayPermission: true,
        submenu: [
          {
            path: "/admin/dashboard/main",
            title: "MENUITEMS.HOME.LIST.DASHBOARD1",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "dashboard.svg",
            role: ['All'],
            displayPermission: true,
            submenu: [],
          },
          {
            path: "/admin/dashboard/menu",
            title: "MENUITEMS.HOME.LIST.Menu",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "menus.svg",
            role: ['All'],
            displayPermission: true,
            submenu: [],
          },
          {
            path: "/admin/dashboard/seatsnew",
            title: "MENUITEMS.HOME.LIST.Seats",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "menus.svg",
            role: ['All'],
            displayPermission: true,
            submenu: [],
          },
          {
            path: "/admin/dashboard/updatehistory",
            title: "COMMON.Updates",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "update-history.svg",
            role: ['All'],
            displayPermission: true,
            submenu: [],
          }
        ],
      },
      //PRODUCTSMANAGEMENT
      {
        path: "",
        title: "MENUITEMS.PRODUCTSMANAGEMENT.TEXT",
        moduleName: "products",
        iconType: "material-icons-two-tone",
        icon: "inventory_2",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "product-management.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(["Permissions.BusinessType.ViewAll", 'Permissions.ProductDepartment.ViewAll', 'Permissions.ProductCategory.ViewAll', 'Permissions.Product.ViewAll', 'Permissions.Modifier.ViewAll', 'Permissions.GiftCard.ViewAll', 'Permissions.Group.ViewAll', 'Permissions.PropertyType.ViewAll', 'Permissions.ProductStatus.ViewAll']),
        submenu: [
          {
            path: "",
            title: "MENUITEMS.BUSINESSTYPE.TEXT",
            moduleName: "businesstype",
            iconType: "material-icons-two-tone",
            icon: "flag",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "business-type.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.BusinessType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/businesstype/allbusinesstype",
                title: "MENUITEMS.BUSINESSTYPE.LIST.All-BUSINESSTYPE",
                moduleName: "businesstype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "business-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BusinessType.ViewAll"),
                claim: "Permissions.BusinessType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/businesstype/addbusinesstype",
                title: "MENUITEMS.BUSINESSTYPE.LIST.NEW-BUSINESSTYPE",
                moduleName: "businesstype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-business-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BusinessType.Create"),
                claim: "Permissions.BusinessType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PRODUCTDEPARTMENT.TEXT",
            moduleName: "productdepartment",
            iconType: "material-icons-two-tone",
            icon: "flag",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "productdepartment.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ProductDepartment.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/productdepartment/allproductdepartment",
                title: "MENUITEMS.PRODUCTDEPARTMENT.LIST.All-PRODUCTDEPARTMENT",
                moduleName: "productdepartment",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProductDepartment.ViewAll"),
                claim: "Permissions.ProductDepartment.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/productdepartment/addproductdepartment",
                title: "MENUITEMS.PRODUCTDEPARTMENT.LIST.NEW-PRODUCTDEPARTMENT",
                moduleName: "productdepartment",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProductDepartment.Create"),
                claim: "Permissions.ProductDepartment.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PRODUCTCATEGORY.TEXT",
            moduleName: "productcategory",
            iconType: "material-icons-two-tone",
            icon: "category",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "productcategory.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ProductCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/productcategory/allproductcategory",
                title: "MENUITEMS.PRODUCTCATEGORY.LIST.All-PRODUCTCATEGORY",
                moduleName: "productcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProductCategory.ViewAll"),
                claim: "Permissions.ProductCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/productcategory/addproductcategory",
                title: "MENUITEMS.PRODUCTCATEGORY.LIST.NEW-PRODUCTCATEGORY",
                moduleName: "productcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProductCategory.Create"),
                claim: "Permissions.ProductCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          }, {
            path: "",
            title: "MENUITEMS.PRODUCTS.TEXT",
            moduleName: "product",
            iconType: "material-icons-two-tone",
            icon: "inventory",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "product.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Product.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/product/allproducts",
                title: "MENUITEMS.PRODUCTS.LIST.All-PRODUCTS",
                moduleName: "product",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Product.ViewAll"),
                claim: "Permissions.Product.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/product/addproduct",
                title: "MENUITEMS.PRODUCTS.LIST.NEW-PRODUCT",
                moduleName: "product",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Product.Create"),
                claim: "Permissions.Product.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.GIFTCARD.TEXT",
            moduleName: "giftcard",
            iconType: "material-icons-two-tone",
            icon: "redeem",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "giftcard.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.GiftCard.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/giftcard/allgiftcards",
                title: "MENUITEMS.GIFTCARD.LIST.All-GIFTCARD",
                moduleName: "giftcard",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.GiftCard.ViewAll"),
                claim: "Permissions.GiftCard.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/giftcard/addgiftcard",
                title: "MENUITEMS.GIFTCARD.LIST.NEW-GIFTCARD",
                moduleName: "giftcard",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.GiftCard.Create"),
                claim: "Permissions.GiftCard.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.GROUP.TEXT",
            moduleName: "group",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "menu.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Group.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/group/allgroup",
                title: "MENUITEMS.GROUP.LIST.All-GROUP",
                moduleName: "group",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "menu.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Group.ViewAll"),
                claim: "Permissions.Group.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/group/addgroup",
                title: "MENUITEMS.GROUP.LIST.NEW-GROUP",
                moduleName: "group",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-menu.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Group.Create"),
                claim: "Permissions.Group.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.MODIFIERS.TEXT",
            moduleName: "modifier",
            iconType: "material-icons-two-tone",
            icon: "add_circle",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "modifier.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Modifier.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/modifier/allmodifiers",
                title: "MENUITEMS.MODIFIERS.LIST.All-MODIFIERS",
                moduleName: "modifier",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Modifier.ViewAll"),
                claim: "Permissions.Modifier.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/modifier/addmodifier",
                title: "MENUITEMS.MODIFIERS.LIST.NEW-MODIFIER",
                moduleName: "modifier",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Modifier.Create"),
                claim: "Permissions.Modifier.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // {
          //   path: "",
          //   title: "MENUITEMS.MODIFIEROPTIONS.TEXT",
          //   moduleName: "modifieroption",
          //   iconType: "material-icons-two-tone",
          //   icon: "flaky",
          //   class: "ml-sub-menu",
          //   groupTitle: false,
          //   iconPath: "modifieroption.svg",
          //   role: ["All"],
          //   displayPermission: this.permissionService.IsAuthorized("Permissions.Modifier.ViewAll"),
          //claim: "Permissions.Modifier.ViewAll",
          // favorite:false,
          //   submenu: [
          //     {
          //       path: "/admin/products/modifieroption/allmodifieroption",
          //       title: "MENUITEMS.MODIFIEROPTIONS.LIST.All-MODIFIEROPTIONS",
          //       moduleName: "modifieroption",
          //       iconType: "",
          //       icon: "",
          //       class: "ml-sub-sub-menu",
          //       groupTitle: false,
          //       iconPath: "dashboard.svg",
          //       role: [""],
          //       displayPermission: this.permissionService.IsAuthorized("Permissions.Modifier.ViewAll"),
          //claim: "Permissions.Modifier.ViewAll",
          // favorite:false,
          //       submenu: [],
          //     },
          //     {
          //       path: "/admin/products/modifieroption/addmodifieroption",
          //       title: "MENUITEMS.MODIFIEROPTIONS.LIST.NEW-MODIFIEROPTION",
          //       moduleName: "modifieroption",
          //       iconType: "",
          //       icon: "",
          //       class: "ml-sub-sub-menu",
          //       groupTitle: false,
          //       iconPath: "dashboard.svg",
          //       role: [""],
          //       displayPermission: this.permissionService.IsAuthorized("Permissions.Modifier.Create"),
          //claim: "Permissions.Modifier.Create",
          // favorite:false,
          //       submenu: [],
          //     }
          //   ],
          // },
          {
            path: "",
            title: "MENUITEMS.PRODUCTSTATUS.TEXT",
            moduleName: "productstatus",
            iconType: "material-icons-two-tone",
            icon: "category",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "status.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ProductStatus.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/productstatus/allproductstatus",
                title: "MENUITEMS.PRODUCTSTATUS.LIST.All-PRODUCTSTATUS",
                moduleName: "productstatus",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "status.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProductStatus.ViewAll"),
                claim: "Permissions.ProductStatus.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/productstatus/addproductstatus",
                title: "MENUITEMS.PRODUCTSTATUS.LIST.NEW-PRODUCTSTATUS",
                moduleName: "productstatus",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-status.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProductStatus.Create"),
                claim: "Permissions.ProductStatus.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PROPERTY.TEXT",
            moduleName: "propertytype",
            iconType: "material-icons-two-tone",
            icon: "request_quote",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "propertytype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PropertyType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/products/propertytype/allpropertytypes",
                title: "MENUITEMS.PROPERTYTYPE.LIST.All-PROPERTYTYPE",
                moduleName: "propertytype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "propertytype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PropertyType.ViewAll"),
                claim: "Permissions.PropertyType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/products/propertytype/addpropertytype",
                title: "MENUITEMS.PROPERTYTYPE.LIST.NEW-PROPERTYTYPE",
                moduleName: "propertytype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-propertytype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PropertyType.Create"),
                claim: "Permissions.PropertyType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ]
      },
      // OFFERSANDDISCOUNTS
      {
        path: "",
        title: "MENUITEMS.OFFERSANDDISCOUNTS.TEXT",
        moduleName: "offersanddiscounts",
        iconType: "material-icons-two-tone",
        icon: "directions_run",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "activity-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Discount.ViewAll', 'Permissions.Promotion.ViewAll', 'Permissions.Coupon.ViewAll', 'Permissions.TimeEvent.ViewAll']),
        submenu: [
          //discount
          {
            path: "",
            title: "MENUITEMS.DISCOUNT.TEXT",
            moduleName: "discount",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "discount.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Discount.ViewAll") && this.permissionService.getSettings().discount.enable_Discount,
            favorite: false,
            submenu: [
              {
                path: "/admin/offersanddiscounts/discount/alldiscounts",
                title: "MENUITEMS.DISCOUNT.LIST.ALL-DISCOUNTS",
                moduleName: "discount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Discount.ViewAll"),
                claim: "Permissions.Discount.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/offersanddiscounts/discount/adddiscount",
                title: "MENUITEMS.DISCOUNT.LIST.NEW-DISCOUNT",
                moduleName: "discount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Discount.Create"),
                claim: "Permissions.Discount.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //promotion
          {
            path: "",
            title: "MENUITEMS.PROMOTION.TEXT",
            moduleName: "promotion",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "promotion.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Promotion.ViewAll") && this.permissionService.getSettings().discount.enable_Promotion,
            favorite: false,
            submenu: [
              {
                path: "/admin/offersanddiscounts/promotion/allpromotions",
                title: "MENUITEMS.PROMOTION.LIST.All-PROMOTIONS",
                moduleName: "promotion",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Promotion.ViewAll"),
                claim: "Permissions.Promotion.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/offersanddiscounts/promotion/addpromotion",
                title: "MENUITEMS.PROMOTION.LIST.NEW-promotion",
                moduleName: "promotion",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Promotion.Create"),
                claim: "Permissions.Promotion.Create",
                favorite: false,
                submenu: [],
              }
            ],
          }, {
            path: "",
            title: "MENUITEMS.COUPONS.TEXT",
            moduleName: "coupon",
            iconType: "material-icons-two-tone",
            icon: "lock_person",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "coupons.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Coupon.ViewAll") && this.permissionService.getSettings().discount.enable_Coupon,
            favorite: false,
            submenu: [
              {
                path: "/admin/offersanddiscounts/coupon/allcoupons",
                title: "MENUITEMS.COUPONS.LIST.ALL-COUPONS",
                moduleName: "coupon",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Coupon.ViewAll"),
                claim: "Permissions.Coupon.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/offersanddiscounts/coupon/addcoupon",
                title: "MENUITEMS.COUPONS.LIST.NEW-COUPON",
                moduleName: "coupon",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Coupon.Create"),
                claim: "Permissions.Coupon.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TIMEEVENT.TEXT",
            moduleName: "timeevent",
            iconType: "material-icons-two-tone",
            icon: "event",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "timeevent.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TimeEvent.ViewAll") && this.permissionService.getSettings().discount.enable_TimeEvent,
            favorite: false,
            submenu: [
              {
                path: "/admin/offersanddiscounts/timeevent/alltimeevent",
                title: "MENUITEMS.TIMEEVENT.LIST.All-TIMEEVENT",
                moduleName: "timeevent",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "timeevent.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TimeEvent.ViewAll"),
                claim: "Permissions.TimeEvent.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/offersanddiscounts/timeevent/addtimeevent",
                title: "MENUITEMS.TIMEEVENT.LIST.NEW-TIMEEVENT",
                moduleName: "timeevent",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-timeevent.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TimeEvent.Create"),
                claim: "Permissions.TimeEvent.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      //INVENTORYMANAGEMENT
      {
        path: "",
        title: "MENUITEMS.INVENTORYMANAGEMENT.TEXT",
        moduleName: "inventory",
        iconType: "material-icons-two-tone",
        icon: "inventory_2",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "inventory-management.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.InventoryDepartment.ViewAll', 'Permissions.InventoryCategory.ViewAll', 'Permissions.InventoryItems.ViewAll', 'Permissions.InventoryDocumentType.ViewAll']),
        submenu: [
          {
            path: "",
            title: "MENUITEMS.INVENTORYDEPARTMENT.TEXT",
            moduleName: "inventorydepartment",
            iconType: "material-icons-two-tone",
            icon: "flag",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "inventorydepartment.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryDepartment.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventory/inventorydepartment/allinventorydepartment",
                title: "MENUITEMS.INVENTORYDEPARTMENT.LIST.All-INVENTORYDEPARTMENT",
                moduleName: "inventorydepartment",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryDepartment.ViewAll"),
                claim: "Permissions.InventoryDepartment.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventory/inventorydepartment/addinventorydepartment",
                title: "MENUITEMS.INVENTORYDEPARTMENT.LIST.NEW-INVENTORYDEPARTMENT",
                moduleName: "inventorydepartment",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryDepartment.Create"),
                claim: "Permissions.InventoryDepartment.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.INVENTORYCATEGORY.TEXT",
            moduleName: "inventorycategory",
            iconType: "material-icons-two-tone",
            icon: "category",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "inventorycategory.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventory/inventorycategory/allinventorycategory",
                title: "MENUITEMS.INVENTORYCATEGORY.LIST.All-INVENTORYCATEGORY",
                moduleName: "inventorycategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryCategory.ViewAll"),
                claim: "Permissions.InventoryCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventory/inventorycategory/addinventorycategory",
                title: "MENUITEMS.INVENTORYCATEGORY.LIST.NEW-INVENTORYCATEGORY",
                moduleName: "inventorycategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryCategory.Create"),
                claim: "Permissions.InventoryCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          }, {
            path: "",
            title: "MENUITEMS.INVENTORYITEM.TEXT",
            moduleName: "inventoryitem",
            iconType: "material-icons-two-tone",
            icon: "request_quote",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "inventory.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryItems.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventory/inventoryitem/allinventoryitems",
                title: "MENUITEMS.INVENTORYITEM.LIST.All-INVENTORYITEMS",
                moduleName: "inventoryitem",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryItems.ViewAll"),
                claim: "Permissions.InventoryItems.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventory/inventoryitem/addinventoryitem",
                title: "MENUITEMS.INVENTORYITEM.LIST.NEW-INVENTORYITEM",
                moduleName: "inventoryitem",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryItems.Create"),
                claim: "Permissions.InventoryItems.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //inventorydocumenttype
          {
            path: "",
            title: "MENUITEMS.INVENTORYDOCUMENTTYPES.TEXT",
            moduleName: "inventorydocumenttype",
            iconType: "material-icons-two-tone",
            icon: "warehouse",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "document-type.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryDocumentType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventory/inventorydocumenttype/allinventorydocumenttypes",
                title: "MENUITEMS.INVENTORYDOCUMENTTYPES.LIST.ALL-INVENTORYDOCUMENTTYPES",
                moduleName: "inventorydocumenttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "document-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryDocumentType.ViewAll"),
                claim: "Permissions.InventoryDocumentType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventory/inventorydocumenttype/addinventorydocumenttype",
                title: "MENUITEMS.INVENTORYDOCUMENTTYPES.LIST.NEW-INVENTORYDOCUMENTTYPE",
                moduleName: "inventorydocumenttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-document-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryDocumentType.Create"),
                claim: "Permissions.InventoryDocumentType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ]
      },
      //inventoryoperations
      {
        path: "",
        title: "MENUITEMS.INVENTORYOPERATIONS.TEXT",
        moduleName: "inventoryoperations",
        iconType: "material-icons-two-tone",
        icon: "inventory_2",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "inventory-operations.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.InventoryCount.ViewAll', 'Permissions.InventoryOpenBalance.ViewAll', 'Permissions.InventoryTransferOrder.ViewAll', 'Permissions.InventoryTransferRequest.ViewAll']),
        submenu: [
          //inventoryopenbalance
          {
            path: "",
            title: "MENUITEMS.INVENTORYOPENBALANCES.TEXT",
            moduleName: "inventoryopenbalance",
            iconType: "material-icons-two-tone",
            icon: "inventory-open-balance",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "inventory-open-balance.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryOpenBalance.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventoryoperations/inventoryopenbalance/allinventoryopenbalances",
                title: "MENUITEMS.INVENTORYOPENBALANCES.LIST.ALL-INVENTORYOPENBALANCES",
                moduleName: "inventoryopenbalance",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "inventory-open-balance.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryOpenBalance.ViewAll"),
                claim: "Permissions.InventoryOpenBalance.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventoryoperations/inventoryopenbalance/addinventoryopenbalance",
                title: "MENUITEMS.INVENTORYOPENBALANCES.LIST.NEW-INVENTORYOPENBALANCE",
                moduleName: "inventoryopenbalance",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-inventory-open-balance.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryOpenBalance.Create"),
                claim: "Permissions.InventoryOpenBalance.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //InventoryTransferOrder
          {
            path: "",
            title: "MENUITEMS.INVENTORYTRANSFERORDERS.TEXT",
            moduleName: "inventorytransferorder",
            iconType: "material-icons-two-tone",
            icon: "inventory-transfer-order",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "inventory-transfer-order.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryTransferOrder.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventoryoperations/inventorytransferorder/allinventorytransferorder",
                title: "MENUITEMS.INVENTORYTRANSFERORDERS.LIST.ALL-INVENTORYTRANSFERORDERS",
                moduleName: "inventorytransferorder",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "inventory-transfer-order.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryTransferOrder.ViewAll"),
                claim: "Permissions.InventoryTransferOrder.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventoryoperations/inventorytransferorder/addinventorytransferorder",
                title: "MENUITEMS.INVENTORYTRANSFERORDERS.LIST.NEW-INVENTORYTRANSFERORDER",
                moduleName: "inventorytransferorder",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-inventory-transfare-order.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryTransferOrder.Create"),
                claim: "Permissions.InventoryTransferOrder.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //InventoryTransferRequest
          {
            path: "",
            title: "MENUITEMS.INVENTORYTRANSFERREQUESTS.TEXT",
            moduleName: "inventorytransferrequest",
            iconType: "material-icons-two-tone",
            icon: "inventory-transfer-order",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "inventory-transfer-order.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryTransferRequest.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventoryoperations/inventorytransferrequest/allinventorytransferrequest",
                title: "MENUITEMS.INVENTORYTRANSFERREQUESTS.LIST.ALL-INVENTORYTRANSFERREQUESTS",
                moduleName: "inventorytransferrequest",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "inventory-transfer-order.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryTransferRequest.ViewAll"),
                claim: "Permissions.InventoryTransferRequest.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventoryoperations/inventorytransferrequest/addinventorytransferrequest",
                title: "MENUITEMS.INVENTORYTRANSFERREQUESTS.LIST.NEW-INVENTORYTRANSFERREQUEST",
                moduleName: "inventorytransferrequest",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "inventory-transfer-order.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryTransferRequest.Create"),
                claim: "Permissions.InventoryTransferRequest.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.INVENTORYCOUNTS.TEXT",
            moduleName: "inventorycount",
            iconType: "material-icons-two-tone",
            icon: "warehouse",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "inventory-count.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryCount.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/inventoryoperations/inventorycount/allinventorycounts",
                title: "MENUITEMS.INVENTORYCOUNTS.LIST.ALL-INVENTORYCOUNTS",
                moduleName: "inventorycount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "inventory-count.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryCount.ViewAll"),
                claim: "Permissions.InventoryCount.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/inventoryoperations/inventorycount/addinventorycount",
                title: "MENUITEMS.INVENTORYCOUNTS.LIST.NEW-INVENTORYCOUNT",
                moduleName: "inventorycount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-inventory-count.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.InventoryCount.Create"),
                claim: "Permissions.InventoryCount.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.GoodsReceivedNote.TEXT",
            moduleName: "goodsreceivednote",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "goodsreceivednote.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.GoodsReceivedNote.ViewAll"),
            submenu: [
              {
                path: "/admin/inventoryoperations/goodsreceivednote/allgoodsreceivednote",
                title: "MENUITEMS.GoodsReceivedNote.LIST.ALL-GoodsReceivedNoteS",
                moduleName: "goodsreceivednote",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.GoodsReceivedNote.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/inventoryoperations/goodsreceivednote/addgoodsreceivednote",
                title: "MENUITEMS.GoodsReceivedNote.LIST.NEW-GoodsReceivedNote",
                moduleName: "goodsreceivednote",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.GoodsReceivedNote.Create"),
                submenu: [],
              }
            ],
          },
        ]
      },
      //POS
      {
        path: "",
        title: "MENUITEMS.POS.TEXT",
        moduleName: "pos",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(["Permissions.Customers.ViewAll", 'Permissions.Shift.ViewAll', "Permissions.Order.ViewAll", "Permissions.Marketer.ViewAll", "Permissions.Salesman.ViewAll", "Permissions.Shift.ViewAll"]),
        submenu: [
          {
            path: "/admin/pos/orderdashboard/orderdashboard",
            title: "MENUITEMS.HOME.LIST.DASHBOARD1",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "dashboard.svg",
            role: ['All'],
            displayPermission: true,
            submenu: [],
          },
          {
            path: "/admin/pos/orderdashboard2/orderdashboard2",
            title: "MENUITEMS.HOME.LIST.DASHBOARD1",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "dashboard.svg",
            role: ['All'],
            displayPermission: true,
            submenu: [],
          },
          {
            path: "",
            title: "MENUITEMS.ORDER.TEXT",
            moduleName: "order",
            iconType: "material-icons-two-tone",
            icon: "apartment",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "roll-invoice.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Order.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/pos/order/allorder",
                title: "MENUITEMS.ORDER.LIST.All-ORDER",
                moduleName: "order",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "roll-invoice.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Order.ViewAll"),
                claim: "Permissions.Order.ViewAll",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Shift.TEXT",
            moduleName: "shift",
            iconType: "material-icons-two-tone",
            icon: "redeem",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "shift.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Shift.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/pos/shift/allshift",
                title: "MENUITEMS.Shift.LIST.All-Shift",
                moduleName: "shift",
                iconType: "material-icons-two-tone",
                icon: "redeem",
                // card_membership
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "shift.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Shift.ViewAll"),
                claim: "Permissions.Shift.ViewAll",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // CUSTOMER
          {
            path: "",
            title: "MENUITEMS.CUSTOMER.TEXT",
            moduleName: "customer",
            iconType: "material-icons-two-tone",
            icon: "business",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "customer.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Customers.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/pos/customer/allcustomers",
                title: "MENUITEMS.CUSTOMER.LIST.All-CUSTOMER",
                moduleName: "customer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "customer.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Customers.ViewAll"),
                claim: "Permissions.Customers.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/pos/customer/addcustomer",
                title: "MENUITEMS.CUSTOMER.LIST.NEW-CUSTOMER",
                moduleName: "customer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-customer.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Customers.Create"),
                claim: "Permissions.Customers.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Marketer.TEXT",
            moduleName: "marketer",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "marketer.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Marketer.ViewAll"),
            submenu: [
              {
                path: "/admin/pos/marketer/allmarketer",
                title: "MENUITEMS.Marketer.LIST.All-Marketer",
                moduleName: "marketer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Marketer.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/pos/marketer/addmarketer",
                title: "MENUITEMS.Marketer.LIST.NEW-Marketer",
                moduleName: "marketer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Marketer.Create"),
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Salesman.TEXT",
            moduleName: "salesman",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "salesman.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Salesman.ViewAll"),
            submenu: [
              {
                path: "/admin/pos/salesman/allsalesman",
                title: "MENUITEMS.Salesman.LIST.All-Salesman",
                moduleName: "salesman",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Salesman.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/pos/salesman/addsalesman",
                title: "MENUITEMS.Salesman.LIST.NEW-Salesman",
                moduleName: "salesman",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Salesman.Create"),
                submenu: [],
              }
            ],
          },
        ],
      },
      //delivery
      {
        path: "",
        title: "MENUITEMS.DELIVERY.TEXT",
        moduleName: "delivery",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.DeliveryCompany.ViewAll', 'Permissions.DeliveryZone.ViewAll', 'Permissions.DeliveryCharge.ViewAll']),
        submenu: [
          //DeliveryCompany
          {
            path: "",
            title: "MENUITEMS.DELIVERYCOMPANIES.TEXT",
            moduleName: "deliverycompany",
            iconType: "material-icons-two-tone",
            icon: "apartment",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "deliverycompany.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryCompany.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/delivery/deliverycompany/alldeliverycompanies",
                title: "MENUITEMS.DELIVERYCOMPANIES.LIST.ALL-DELIVERYCOMPANIES",
                moduleName: "deliverycompany",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "deliverycompany.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryCompany.ViewAll"),
                claim: "Permissions.DeliveryCompany.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/delivery/deliverycompany/adddeliverycompany",
                title: "MENUITEMS.DELIVERYCOMPANIES.LIST.NEW-DELIVERYCOMPANY",
                moduleName: "deliverycompany",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-deliverycompany.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryCompany.Create"),
                claim: "Permissions.DeliveryCompany.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.DELIVERYZONE.TEXT",
            moduleName: "deliveryzone",
            iconType: "material-icons-two-tone",
            icon: "map",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "deliveryzone.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryZone.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/delivery/deliveryzone/alldeliveryzone",
                title: "MENUITEMS.DELIVERYZONE.LIST.All-DELIVERYZONE",
                moduleName: "deliveryzone",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryZone.ViewAll"),
                claim: "Permissions.DeliveryZone.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/delivery/deliveryzone/adddeliveryzone",
                title: "MENUITEMS.DELIVERYZONE.LIST.NEW-DELIVERYZONE",
                moduleName: "deliveryzone",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryZone.Create"),
                claim: "Permissions.DeliveryZone.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.DELIVERYCHARGES.TEXT",
            moduleName: "deliverycharges",
            iconType: "material-icons-two-tone",
            icon: "request_quote",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "delivery-charge.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryCharge.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/delivery/deliverycharges/alldeliverycharges",
                title: "MENUITEMS.DELIVERYCHARGES.LIST.ALL-DELIVERYCHARGES",
                moduleName: "deliverycharges",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "delivery-charge.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryCharge.ViewAll"),
                claim: "Permissions.DeliveryCharge.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/delivery/deliverycharges/adddeliverycharge",
                title: "MENUITEMS.DELIVERYCHARGES.LIST.NEW-DELIVERYCHARGE",
                moduleName: "deliverycharges",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-delivery-charge.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeliveryCharge.Create"),
                claim: "Permissions.DeliveryCharge.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      //restaurantsandcoffees
      {
        path: "",
        title: "MENUITEMS.RESTAURANTSANDCOFFEES.TEXT",
        moduleName: "restaurantsandcoffees",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.RestaurantSection.ViewAll', 'Permissions.Waiter.ViewAll', "Permissions.KitchenFLow.ViewAll"]),
        submenu: [
          //RESTAURANTSECTION
          {
            path: "",
            title: "MENUITEMS.RESTAURANTTABLE.TEXT",
            moduleName: "restaurantsection",
            iconType: "material-icons-two-tone",
            icon: "event_seat",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "table.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.RestaurantSection.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/restaurantsandcoffees/restaurantsection/allrestaurantsections",
                title: "MENUITEMS.RESTAURANTSECTION.LIST.All-RESTAURANTSECTION",
                moduleName: "restaurantsection",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "section.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RestaurantSection.ViewAll"),
                claim: "Permissions.RestaurantSection.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/restaurantsandcoffees/restaurantsection/addrestaurantsection",
                title: "MENUITEMS.RESTAURANTSECTION.LIST.NEW-RESTAURANTSECTION",
                moduleName: "restaurantsection",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-section.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RestaurantSection.Create"),
                claim: "Permissions.RestaurantSection.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.KITCHENFLOW.TEXT",
            moduleName: "kitchenflow",
            iconType: "material-icons-two-tone",
            icon: "apartment",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "roll-invoice.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.KitchenFLow.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/restaurantsandcoffees/kitchenflow/allkitchenflow",
                title: "MENUITEMS.KITCHENFLOW.LIST.All-KITCHENFLOW",
                moduleName: "kitchenflow",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "roll-invoice.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.KitchenFLow.ViewAll"),
                claim: "Permissions.KitchenFLow.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/restaurantsandcoffees/kitchenflow/addkitchenflow",
                title: "MENUITEMS.KITCHENFLOW.LIST.NEW-KITCHENFLOW",
                moduleName: "kitchenflow",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-roll-invoice.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.KitchenFLow.Create"),
                claim: "Permissions.KitchenFLow.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Waiter.TEXT",
            moduleName: "waiter",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "waiter.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Waiter.ViewAll"),
            submenu: [
              {
                path: "/admin/restaurantsandcoffees/waiter/allwaiter",
                title: "MENUITEMS.Waiter.LIST.All-Waiter",
                moduleName: "waiter",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Waiter.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/restaurantsandcoffees/waiter/addwaiter",
                title: "MENUITEMS.Waiter.LIST.NEW-Waiter",
                moduleName: "waiter",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Waiter.Create"),
                submenu: [],
              }
            ],
          }
        ],
      },
      //management
      {
        path: "",
        title: "MENUITEMS.MANAGEMENT.TEXT",
        moduleName: "management",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(["Permissions.Currency.ViewAll", "Permissions.PaymentMethod.ViewAll", "Permissions.Tax.ViewAll", "Permissions.UnitOfMeasure.ViewAll", 'Permissions.Warehouses.ViewAll', "Permissions.Fee.ViewAll", "Permissions.PriceList.ViewAll", "Permissions.LookupGroup.ViewAll", "Permissions.FollowUpOperationType.ViewAll", "Permissions.SalesInvoiceTemplate.ViewAll"]),
        submenu: [
          //CURRENCY
          {
            path: "",
            title: "MENUITEMS.CURRENCY.TEXT",
            moduleName: "currency",
            iconType: "material-icons-two-tone",
            icon: "attach_money",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "currency.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Currency.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/currency/allcurrency",
                title: "MENUITEMS.CURRENCY.LIST.All-CURRENCY",
                moduleName: "currency",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "currency.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Currency.ViewAll"),
                claim: "Permissions.Currency.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/currency/addcurrency",
                title: "MENUITEMS.CURRENCY.LIST.NEW-CURRENCY",
                moduleName: "currency",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-currency.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Currency.Create"),
                claim: "Permissions.Currency.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //PaymentMethod
          {
            path: "",
            title: "MENUITEMS.PAYMENTMETHODS.TEXT",
            moduleName: "paymentmethod",
            iconType: "material-icons-two-tone",
            icon: "payments",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "paymentmethod.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PaymentMethod.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/paymentmethod/allpaymentmethods",
                title: "MENUITEMS.PAYMENTMETHODS.LIST.ALL-PAYMENTMETHODS",
                moduleName: "paymentmethod",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "paymentmethod.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PaymentMethod.ViewAll"),
                claim: "Permissions.PaymentMethod.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/paymentmethod/addpaymentmethod",
                title: "MENUITEMS.PAYMENTMETHODS.LIST.NEW-PAYMENTMETHOD",
                moduleName: "paymentmethod",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-paymentmethod.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PaymentMethod.Create"),
                claim: "Permissions.PaymentMethod.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // TAXGROUP
          {
            path: "",
            title: "MENUITEMS.TAXGROUP.TEXT",
            moduleName: "taxgroup",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "tax.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tax.ViewAll") && this.permissionService.getSettings().tax.enable_Sales_Tax,
            favorite: false,
            submenu: [
              {
                path: "/admin/management/taxgroup/alltaxgroup",
                title: "MENUITEMS.TAXGROUP.LIST.All-TAXGROUP",
                moduleName: "taxgroup",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "tax.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tax.ViewAll") && this.permissionService.getSettings().tax.enable_Sales_Tax,
                claim: "Permissions.Tax.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/taxgroup/addtaxgroup",
                title: "MENUITEMS.TAXGROUP.LIST.NEW-TAXGROUP",
                moduleName: "taxgroup",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-tax.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tax.Create") && this.permissionService.getSettings().tax.enable_Sales_Tax,
                claim: "Permissions.Tax.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // TAX
          {
            path: "",
            title: "MENUITEMS.TAX.TEXT",
            moduleName: "tax",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "tax.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tax.ViewAll") && this.permissionService.getSettings().tax.enable_Sales_Tax,
            favorite: false,
            submenu: [
              {
                path: "/admin/management/tax/alltaxes",
                title: "MENUITEMS.TAX.LIST.All-TAXES",
                moduleName: "tax",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "tax.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tax.ViewAll") && this.permissionService.getSettings().tax.enable_Sales_Tax,
                claim: "Permissions.Tax.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/tax/addtax",
                title: "MENUITEMS.TAX.LIST.NEW-TAX",
                moduleName: "tax",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-tax.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tax.Create") && this.permissionService.getSettings().tax.enable_Sales_Tax,
                claim: "Permissions.Tax.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.FinancialYears.TEXT",
            moduleName: "financialyears",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "financialyears.svg",
            role: ["All"],
            displayPermission: true,
            submenu: [

              {
                path: "/admin/management/financialyears/allfinancialyears",
                title: "MENUITEMS.FinancialYears.LIST.All-FinancialYears",
                moduleName: "financialyears",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              },
              {
                path: "/admin/management/financialyears/addfinancialyears",
                title: "MENUITEMS.FinancialYears.LIST.NEW-FinancialYears",
                moduleName: "financialyears",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.UNITOFMEASURE.TEXT",
            moduleName: "unitofmeasure",
            iconType: "material-icons-two-tone",
            icon: "scale",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "unitofmeasure.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.UnitOfMeasure.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/unitofmeasure/allunitofmeasures",
                title: "MENUITEMS.UNITOFMEASURE.LIST.All-UNITOFMEASURES",
                moduleName: "unitofmeasure",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "unitofmeasure.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.UnitOfMeasure.ViewAll"),
                claim: "Permissions.UnitOfMeasure.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/unitofmeasure/addunitofmeasure",
                title: "MENUITEMS.UNITOFMEASURE.LIST.NEW-UNITOFMEASURE",
                moduleName: "unitofmeasure",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-unitofmeasure.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.UnitOfMeasure.Create"),
                claim: "Permissions.UnitOfMeasure.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //Warehouses
          {
            path: "",
            title: "MENUITEMS.WAREHOUSES.TEXT",
            moduleName: "warehouse",
            iconType: "material-icons-two-tone",
            icon: "warehouse",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "warehouse.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Warehouses.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/warehouse/allwarehouses",
                title: "MENUITEMS.WAREHOUSES.LIST.ALL-WAREHOUSES",
                moduleName: "warehouse",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "warehouse.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Warehouses.ViewAll"),
                claim: "Permissions.Warehouses.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/warehouse/addwarehouse",
                title: "MENUITEMS.WAREHOUSES.LIST.NEW-WAREHOUSE",
                moduleName: "warehouse",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-warehouse.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Warehouses.Create"),
                claim: "Permissions.Warehouses.Create",
                favorite: false,
                submenu: [],
              }
            ]
          },
          {
            path: "",
            title: "MENUITEMS.FEE.TEXT",
            moduleName: "fee",
            iconType: "material-icons-two-tone",
            icon: "request_quote",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "delivery-charge.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Fee.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/fee/allfees",
                title: "MENUITEMS.FEE.LIST.ALL-FEE",
                moduleName: "fee",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "delivery-charge.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Fee.ViewAll"),
                claim: "Permissions.Fee.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/fee/addfee",
                title: "MENUITEMS.FEE.LIST.NEW-FEE",
                moduleName: "fee",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-delivery-charge.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Fee.Create"),
                claim: "Permissions.Fee.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PRICELISTS.TEXT",
            moduleName: "pricelist",
            iconType: "material-icons-two-tone",
            icon: "request_quote",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "priceList.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PriceList.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/pricelist/allpricelists",
                title: "MENUITEMS.PRICELISTS.LIST.All-PRICELISTS",
                moduleName: "pricelist",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "priceList.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PriceList.ViewAll"),
                claim: "Permissions.PriceList.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/pricelist/addpricelist",
                title: "MENUITEMS.PRICELISTS.LIST.NEW-PRICELIST",
                moduleName: "pricelist",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-pricelist.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PriceList.Create"),
                claim: "Permissions.PriceList.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.LOOKUP.TEXT",
            moduleName: "lookupgroup",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "lookupgroup.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.LookupGroup.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/lookupgroup/alllookupgroup",
                title: "MENUITEMS.LOOKUPGROUP.LIST.All-LOOKUPGROUP",
                moduleName: "lookupgroup",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "lookupgroup.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.LookupGroup.ViewAll"),
                claim: "Permissions.LookupGroup.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/lookupgroup/addlookupgroup",
                title: "MENUITEMS.LOOKUPGROUP.LIST.NEW-LOOKUPGROUP",
                moduleName: "lookupgroup",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-lookupgroup.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.LookupGroup.Create"),
                claim: "Permissions.LookupGroup.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.FOLLOWUPOPERATIONTYPE.TEXT",
            moduleName: "followupoperationtype",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "lookupgroup.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.FollowUpOperationType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/followupoperationtype/allfollowupoperationtype",
                title: "MENUITEMS.FOLLOWUPOPERATIONTYPE.LIST.ALL-FOLLOWUPOPERATIONTYPE",
                moduleName: "followupoperationtype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "lookupgroup.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.FollowUpOperationType.ViewAll"),
                claim: "Permissions.FollowUpOperationType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/followupoperationtype/addfollowupoperationtype",
                title: "MENUITEMS.FOLLOWUPOPERATIONTYPE.LIST.NEW-FOLLOWUPOPERATIONTYPE",
                moduleName: "followupoperationtype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-lookupgroup.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.FollowUpOperationType.Create"),
                claim: "Permissions.FollowUpOperationType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SALESINVOICETEMPLATE.TEXT",
            moduleName: "salesinvoicetemplate",
            iconType: "material-icons-two-tone",
            icon: "apartment",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "roll-invoice.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.SalesInvoiceTemplate.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/salesinvoicetemplate/allsalesinvoicetemplate",
                title: "MENUITEMS.SALESINVOICETEMPLATE.LIST.ALL-SALESINVOICETEMPLATE",
                moduleName: "salesinvoicetemplate",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "roll-invoice.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SalesInvoiceTemplate.ViewAll"),
                claim: "Permissions.SalesInvoiceTemplate.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/salesinvoicetemplate/addsalesinvoicetemplate",
                title: "MENUITEMS.SALESINVOICETEMPLATE.LIST.NEW-SALESINVOICETEMPLATE",
                moduleName: "salesinvoicetemplate",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-roll-invoice.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SalesInvoiceTemplate.Create"),
                claim: "Permissions.SalesInvoiceTemplate.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SALESINVOICETEMPLATEA.TEXT",
            moduleName: "salesinvoicetemplatea",
            iconType: "material-icons-two-tone",
            icon: "apartment",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "invoice-a4.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.SalesInvoiceTemplate.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/management/salesinvoicetemplatea/allsalesinvoicetemplatea",
                title: "MENUITEMS.SALESINVOICETEMPLATEA.LIST.ALL-SALESINVOICETEMPLATEA",
                moduleName: "salesinvoicetemplatea",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "invoice-a4.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SalesInvoiceTemplate.ViewAll"),
                claim: "Permissions.SalesInvoiceTemplate.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/salesinvoicetemplatea/addsalesinvoicetemplatea",
                title: "MENUITEMS.SALESINVOICETEMPLATEA.LIST.NEW-SALESINVOICETEMPLATEA",
                moduleName: "salesinvoicetemplatea",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-invoice-a4.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SalesInvoiceTemplate.Create"),
                claim: "Permissions.SalesInvoiceTemplate.Create",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/management/appsettings/appsettings",
                title: "MENUITEMS.APPSETTINGS.TEXT",
                moduleName: "settings",
                iconType: "material-icons-two-tone",
                icon: "settings",
                class: "",
                groupTitle: false,
                iconPath: "appsetting.svg",
                role: ["All"],
                displayPermission: this.permissionService.IsAuthorized("Permissions.AppSetting.View"),
                claim: "Permissions.AppSetting.View",
                favorite: false,
                submenu: [],
              }
            ],
          }
        ],
      },
      //financesettings
      {
        path: "",
        title: "MENUITEMS.FINANCESSETTINGS.TEXT",
        moduleName: "financesettings",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "finance-settings.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(["Permissions.CostCenters.ViewAll", "Permissions.Accounts.ViewAll", 'Permissions.CashAccounts.ViewAll', 'Permissions.VoucherType.ViewAll']),
        submenu: [
          // JurnalEntryCategory
          {
            path: "",
            title: "MENUITEMS.JurnalEntryCategory.TEXT",
            moduleName: "jurnalEntryCategory",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "jurnalEntryCategory.svg",
            role: ["All"],
            displayPermission: true,
            submenu: [
              {
                path: "/admin/financesettings/jurnalEntryCategory/alljurnalEntryCategory",
                title: "MENUITEMS.JurnalEntryCategory.LIST.All-JurnalEntryCategory",
                moduleName: "JurnalEntryCategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              },
              {
                path: "/admin/financesettings/jurnalEntryCategory/addjurnalEntryCategory",
                title: "MENUITEMS.JurnalEntryCategory.LIST.NEW-JurnalEntryCategory",
                moduleName: "JurnalEntryCategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              }
            ],
          },
          //VoucherType
          {
            path: "",
            title: "MENUITEMS.VOUCHERTYPE.TEXT",
            moduleName: "vouchertype",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "voucher-type.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VoucherType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/financesettings/vouchertype/allvouchertypes",
                title: "MENUITEMS.VOUCHERTYPE.LIST.ALL-VOUCHERTYPES",
                moduleName: "vouchertype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "voucher-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VoucherType.ViewAll"),
                claim: "Permissions.VoucherType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/financesettings/vouchertype/addvouchertype",
                title: "MENUITEMS.VOUCHERTYPE.LIST.NEW-VOUCHERTYPE",
                moduleName: "vouchertype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-voucher-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VoucherType.Create"),
                claim: "Permissions.VoucherType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //ANALYTICALNAMETYPE
          {
            path: "",
            title: "MENUITEMS.ANALYTICALNAMETYPE.TEXT",
            moduleName: "analyticalnametype",
            iconType: "material-icons-two-tone",
            icon: "hail",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "accounts.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Accounts.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/financesettings/analyticalnametype/allanalyticalnametype",
                title: "MENUITEMS.ANALYTICALNAMETYPE.LIST.ALL-ANALYTICALNAMETYPE",
                moduleName: "analyticalnametype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Accounts.ViewAll"),
                claim: "Permissions.Accounts.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/financesettings/analyticalnametype/addanalyticalnametype",
                title: "MENUITEMS.ANALYTICALNAMETYPE.LIST.NEW-ANALYTICALNAMETYPE",
                moduleName: "analyticalnametype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Accounts.Create"),
                claim: "Permissions.Accounts.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //account
          {
            path: "",
            title: "MENUITEMS.ACCOUNTS.TEXT",
            moduleName: "account",
            iconType: "material-icons-two-tone",
            icon: "hail",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "accounts.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Accounts.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/financesettings/account/allaccounts",
                title: "MENUITEMS.ACCOUNTS.LIST.ALL-ACCOUNTS",
                moduleName: "account",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Accounts.ViewAll"),
                claim: "Permissions.Accounts.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/financesettings/account/addaccount",
                title: "MENUITEMS.ACCOUNTS.LIST.NEW-ACCOUNT",
                moduleName: "account",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Accounts.Create"),
                claim: "Permissions.Accounts.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //COSTCENTERS
          {
            path: "",
            title: "MENUITEMS.COSTCENTERS.TEXT",
            moduleName: "costcenter",
            iconType: "material-icons-two-tone",
            icon: "payments",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "add-tax.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.CostCenters.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/financesettings/costcenter/allcostcenters",
                title: "MENUITEMS.COSTCENTERS.LIST.ALL-COSTCENTERS",
                moduleName: "costcenter",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-tax.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CostCenters.ViewAll"),
                claim: "Permissions.CostCenters.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/financesettings/costcenter/addcostcenter",
                title: "MENUITEMS.COSTCENTERS.LIST.NEW-COSTCENTER",
                moduleName: "costcenter",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-tax.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CostCenters.Create"),
                claim: "Permissions.CostCenters.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //CASHACCOUNTS
          {
            path: "",
            title: "MENUITEMS.CASHACCOUNTS.TEXT",
            moduleName: "cashaccount",
            iconType: "material-icons-two-tone",
            icon: "payments",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "cash-accounts.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/financesettings/cashaccount/allcashaccounts",
                title: "MENUITEMS.CASHACCOUNTS.LIST.ALL-CASHACCOUNTS",
                moduleName: "cashaccount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "cash-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.ViewAll"),
                claim: "Permissions.CashAccounts.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/financesettings/cashaccount/addcashaccount",
                title: "MENUITEMS.CASHACCOUNTS.LIST.NEW-CASHACCOUNT",
                moduleName: "cashaccount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-cash-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.Create"),
                claim: "Permissions.CashAccounts.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // BANKACCOUNTS
          {
            path: "",
            title: "MENUITEMS.BANKACCOUNTS.TEXT",
            moduleName: "bankaccount",
            iconType: "material-icons-two-tone",
            icon: "payments",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "cash-accounts.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/financesettings/bankaccount/allbankaccounts",
                title: "MENUITEMS.BANKACCOUNTS.LIST.ALL-BANKACCOUNTS",
                moduleName: "bankaccount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "cash-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.ViewAll"),
                claim: "Permissions.CashAccounts.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/financesettings/bankaccount/addbankaccount",
                title: "MENUITEMS.BANKACCOUNTS.LIST.NEW-BANKACCOUNT",
                moduleName: "bankaccount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-cash-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.Create"),
                claim: "Permissions.CashAccounts.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // BANKCARDACCOUNTS
          {
            path: "",
            title: "MENUITEMS.BANKCARDACCOUNTS.TEXT",
            moduleName: "bankcardaccount",
            iconType: "material-icons-two-tone",
            icon: "payments",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "cash-accounts.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/financesettings/bankcardaccount/allbankcardaccounts",
                title: "MENUITEMS.BANKCARDACCOUNTS.LIST.ALL-BANKCARDACCOUNTS",
                moduleName: "bankcardaccount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "cash-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.ViewAll"),
                claim: "Permissions.CashAccounts.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/financesettings/bankcardaccount/addbankcardaccount",
                title: "MENUITEMS.BANKCARDACCOUNTS.LIST.NEW-BANKCARDACCOUNT",
                moduleName: "bankcardaccount",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-cash-accounts.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CashAccounts.Create"),
                claim: "Permissions.CashAccounts.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // AccountOpenBalance
          {
            path: "",
            title: "MENUITEMS.AccountOpenBalance.TEXT",
            moduleName: "accountopenbalance",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "accountOpenBalance.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.AccountOpenBalance.ViewAll"),
            submenu: [

              {
                path: "/admin/financesettings/accountopenbalance/allaccountopenbalance",
                title: "MENUITEMS.AccountOpenBalance.LIST.All-AccountOpenBalance",
                moduleName: "AccountOpenBalance",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.AccountOpenBalance.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/financesettings/accountopenbalance/addaccountopenbalance",
                title: "MENUITEMS.AccountOpenBalance.LIST.NEW-AccountOpenBalance",
                moduleName: "AccountOpenBalance",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.AccountOpenBalance.Create"),
                submenu: [],
              }
            ],
          },
          // Budget
          {
            path: "",
            title: "MENUITEMS.Budget.TEXT",
            moduleName: "budget",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "budgets.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Budget.ViewAll"),
            submenu: [

              {
                path: "/admin/financesettings/budget/allbudget",
                title: "MENUITEMS.Budget.LIST.ALL-Budgets",
                moduleName: "Budget",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Budget.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/financesettings/budget/addbudget",
                title: "MENUITEMS.Budget.LIST.NEW-Budget",
                moduleName: "Budget",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Budget.Create"),
                submenu: [],
              }
            ],
          },
        ],
      },
      // financedocuments
      {
        path: "",
        title: "MENUITEMS.FINANCEDOCUMENTS.TEXT",
        moduleName: "financedocuments",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "finance-transaction-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorized("Permissions.Suppliers.Create"),
        submenu: [
          {
            path: "",
            title: "MENUITEMS.AccountingEntry.TEXT",
            moduleName: "accountingentry",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "jounrelentryrequset.svg",
            role: ["All"],
            displayPermission: true,
            submenu: [
              {
                path: "/admin/financedocuments/accountingentry/allaccountingentry",
                title: "MENUITEMS.AccountingEntry.LIST.All-AccountingEntry",
                moduleName: "AccountingEntry",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              },
              {
                path: "/admin/financedocuments/accountingentry/addaccountingentry",
                title: "MENUITEMS.AccountingEntry.LIST.NEW-AccountingEntry",
                moduleName: "AccountingEntry",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.JournalEntry.TEXT",
            moduleName: "journalentry",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "jounrelentry.svg",
            role: ["All"],
            displayPermission: true,
            submenu: [

              {
                path: "/admin/financedocuments/journalentry/alljournalentry",
                title: "MENUITEMS.JournalEntry.LIST.All-JournalEntry",
                moduleName: "JournalEntry",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              },
              {
                path: "/admin/financedocuments/journalentry/addjournalentry",
                title: "MENUITEMS.JournalEntry.LIST.NEW-JournalEntry",
                moduleName: "JournalEntry",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.RECEIPTVOUCHER.TEXT",
            moduleName: "receiptvoucher",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "receipt-voucher.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ReceiptVoucher.ViewAll"),
            submenu: [

              {
                path: "/admin/financedocuments/receiptvoucher/allreceiptvoucher",
                title: "MENUITEMS.RECEIPTVOUCHER.LIST.ALL-RECEIPTVOUCHERS",
                moduleName: "ReceiptVoucher",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ReceiptVoucher.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/financedocuments/receiptvoucher/addreceiptvoucher",
                title: "MENUITEMS.RECEIPTVOUCHER.LIST.NEW-RECEIPTVOUCHER",
                moduleName: "ReceiptVoucher",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ReceiptVoucher.Create"),
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PAYMENTVOUCHER.TEXT",
            moduleName: "paymentvoucher",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "payment.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PaymentVoucher.ViewAll"),
            submenu: [

              {
                path: "/admin/financedocuments/paymentvoucher/allpaymentvoucher",
                title: "MENUITEMS.PAYMENTVOUCHER.LIST.ALL-PAYMENTVOUCHERS",
                moduleName: "PaymentVoucher",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PaymentVoucher.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/financedocuments/paymentvoucher/addpaymentvoucher",
                title: "MENUITEMS.PAYMENTVOUCHER.LIST.NEW-PAYMENTVOUCHER",
                moduleName: "PaymentVoucher",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PaymentVoucher.Create"),
                submenu: [],
              }
            ],
          },
        ]
      },
      // PurchaseSettings
      {
        path: "",
        title: "MENUITEMS.PurchasingSettings.TEXT",
        moduleName: "purchasingsettings",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(["Permissions.Suppliers.ViewAll", "Permissions.PurchaseSetting.ViewAll"]),
        submenu: [
          //Supplier
          {
            path: "",
            title: "MENUITEMS.SUPPLIERS.TEXT",
            moduleName: "supplier",
            iconType: "material-icons-two-tone",
            icon: "local_shipping",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "supplier.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Suppliers.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchasingsettings/supplier/allsuppliers",
                title: "MENUITEMS.SUPPLIERS.LIST.ALL-SUPPLIERS",
                moduleName: "supplier",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "supplier.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Suppliers.ViewAll"),
                claim: "Permissions.Suppliers.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchasingsettings/supplier/addsupplier",
                title: "MENUITEMS.SUPPLIERS.LIST.NEW-SUPPLIER",
                moduleName: "supplier",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-supplier.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Suppliers.Create"),
                claim: "Permissions.Suppliers.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //PurchaseDocumentType
          {
            path: "",
            title: "MENUITEMS.PURCHASEDOCUMENTTYPE.TEXT",
            moduleName: "purchasedocumenttype",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "document-type.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchasingsettings/purchasedocumenttype/allpurchasedocumenttypes",
                title: "MENUITEMS.PURCHASEDOCUMENTTYPE.LIST.ALL-PURCHASEDOCUMENTTYPES",
                moduleName: "purchasedocumenttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "document-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.ViewAll"),
                claim: "Permissions.PurchaseSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchasingsettings/purchasedocumenttype/addpurchasedocumenttype",
                title: "MENUITEMS.PURCHASEDOCUMENTTYPE.LIST.NEW-PURCHASEDOCUMENTTYPE",
                moduleName: "purchasedocumenttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-document-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.Create"),
                claim: "Permissions.PurchaseSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PurchasePaymentTerm.TEXT",
            moduleName: "purchasepaymentterm",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "receipt-voucher.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.ViewAll"),
            submenu: [
              {
                path: "/admin/purchasingsettings/purchasepaymentterm/allpurchasepaymentterm",
                title: "MENUITEMS.PurchasePaymentTerm.LIST.All-PurchasePaymentTerm",
                moduleName: "purchasepaymentterm",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/purchasingsettings/purchasepaymentterm/addpurchasepaymentterm",
                title: "MENUITEMS.PurchasePaymentTerm.LIST.NEW-PurchasePaymentTerm",
                moduleName: "purchasepaymentterm",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.Create"),
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PurchaseCostType.TEXT",
            moduleName: "purchasecosttype",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "receipt-voucher.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.ViewAll"),
            submenu: [
              {
                path: "/admin/purchasingsettings/purchasecosttype/allpurchasecosttype",
                title: "MENUITEMS.PurchaseCostType.LIST.All-PurchaseCostType",
                moduleName: "purchasecosttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/purchasingsettings/purchasecosttype/addpurchasecosttype",
                title: "MENUITEMS.PurchaseCostType.LIST.NEW-PurchaseCostType",
                moduleName: "purchasecosttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseSetting.Create"),
                submenu: [],
              }
            ],
          },
        ]
      },
      //PURCHASE
      {
        path: "",
        title: "MENUITEMS.PURCHASE.TEXT",
        moduleName: "purchase",
        iconType: "material-icons-two-tone",
        icon: "directions_run",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "purchase.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.PurchaseSetting.ViewAll', 'Permissions.PurchaseRequest.ViewAll', 'Permissions.PurchaseQuotation.ViewAll', 'Permissions.PurchaseInvoice.ViewAll']),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.HOME.LIST.DASHBOARD1",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "purchase.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Suppliers.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchase/purchasedashboard/main",
                title: "MENUITEMS.PURCHASE.LIST.PURCHASEDASHBOARD",
                moduleName: "purchase",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "purchase.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Suppliers.ViewAll"),
                claim: "Permissions.Suppliers.ViewAll",
                favorite: false,
                submenu: [],
              },
            ],
          },
          //PurchaseRequest
          {
            path: "",
            title: "MENUITEMS.PURCHASEREQUESTS.TEXT",
            moduleName: "purchaserequest",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "purchase-requests.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseRequest.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchase/purchaserequest/allpurchaserequests",
                title: "MENUITEMS.PURCHASEREQUESTS.LIST.ALL-PURCHASEREQUESTS",
                moduleName: "purchaserequest",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "purchase-requests.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseRequest.ViewAll"),
                claim: "Permissions.PurchaseRequest.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchase/purchaserequest/addpurchaserequest",
                title: "MENUITEMS.PURCHASEREQUESTS.LIST.NEW-PURCHASEREQUEST",
                moduleName: "purchaserequest",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-purchase-requests.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseRequest.Create"),
                claim: "Permissions.PurchaseRequest.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //PURCHASEQUOTATIONSREQUEST
          {
            path: "",
            title: "MENUITEMS.PURCHASEQUOTATIONSREQUEST.TEXT",
            moduleName: "purchasequotationrequest",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "purchase-quotation.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseQuotationRequest.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchase/purchasequotationrequest/allpurchasequotationrequest",
                title: "MENUITEMS.PURCHASEQUOTATIONSREQUEST.LIST.ALL-PURCHASEQUOTATIONSREQUEST",
                moduleName: "purchasequotationrequest",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "purchase-quotation.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseQuotationRequest.ViewAll"),
                claim: "Permissions.PurchaseQuotationRequest.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchase/purchasequotationrequest/addpurchasequotationrequest",
                title: "MENUITEMS.PURCHASEQUOTATIONSREQUEST.LIST.NEW-PURCHASEQUOTATIONSREQUEST",
                moduleName: "purchasequotation",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-purchase-quotation.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseQuotationRequest.Create"),
                claim: "Permissions.PurchaseQuotationRequest.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //PurchaseQuotation
          {
            path: "",
            title: "MENUITEMS.PURCHASEQUOTATIONS.TEXT",
            moduleName: "purchasequotation",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "purchase-quotation.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseQuotation.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchase/purchasequotation/allpurchasequotations",
                title: "MENUITEMS.PURCHASEQUOTATIONS.LIST.ALL-PURCHASEQUOTATIONS",
                moduleName: "purchasequotation",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "purchase-quotation.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseQuotation.ViewAll"),
                claim: "Permissions.PurchaseQuotation.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchase/purchasequotation/addpurchasequotation",
                title: "MENUITEMS.PURCHASEQUOTATIONS.LIST.NEW-PURCHASEQUOTATION",
                moduleName: "purchasequotation",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-purchase-quotation.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseQuotation.Create"),
                claim: "Permissions.PurchaseQuotation.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PurchaseOrder.TEXT",
            moduleName: "purchaseorder",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "purchase-invoices.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseOrder.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchase/purchaseorder/allpurchaseorders",
                title: "MENUITEMS.PurchaseOrder.LIST.ALL-PurchaseOrderS",
                moduleName: "purchaseorder",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "purchase-invoices.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseOrder.ViewAll"),
                claim: "Permissions.PurchaseOrder.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchase/purchaseorder/addpurchaseorder",
                title: "MENUITEMS.PurchaseOrder.LIST.NEW-PurchaseOrder",
                moduleName: "purchaseorder",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-purchase-invoices.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseOrder.Create"),
                claim: "Permissions.PurchaseOrder.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //PurchaseInvoice
          {
            path: "",
            title: "MENUITEMS.PURCHASEINVOICE.TEXT",
            moduleName: "purchaseinvoice",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "purchase-invoices.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseInvoice.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchase/purchaseinvoice/allpurchaseinvoices",
                title: "MENUITEMS.PURCHASEINVOICE.LIST.ALL-PURCHASEINVOICES",
                moduleName: "purchaseinvoice",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "purchase-invoices.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseInvoice.ViewAll"),
                claim: "Permissions.PurchaseInvoice.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchase/purchaseinvoice/addpurchaseinvoice",
                title: "MENUITEMS.PURCHASEINVOICE.LIST.NEW-PURCHASEINVOICE",
                moduleName: "purchaseinvoice",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-purchase-invoices.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseInvoice.Create"),
                claim: "Permissions.PurchaseInvoice.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PurchaseReturnInvoice.TEXT",
            moduleName: "purchasereturninvoice",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "purchase-invoices.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseReturnInvoice.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/purchase/purchasereturninvoice/allpurchasereturninvoices",
                title: "MENUITEMS.PurchaseReturnInvoice.LIST.ALL-PurchaseReturnInvoiceS",
                moduleName: "purchasereturninvoice",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "purchase-invoices.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseReturnInvoice.ViewAll"),
                claim: "Permissions.PurchaseReturnInvoice.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/purchase/purchasereturninvoice/addpurchasereturninvoice",
                title: "MENUITEMS.PurchaseReturnInvoice.LIST.NEW-PurchaseReturnInvoice",
                moduleName: "purchasereturninvoice",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-purchase-invoices.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PurchaseReturnInvoice.Create"),
                claim: "Permissions.PurchaseReturnInvoice.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      //PROJECTMANAGEMENT
      {
        path: "",
        title: "MENUITEMS.PROJECTMANAGEMENT.TEXT",
        moduleName: "projectmanagement",
        iconType: "material-icons-two-tone",
        icon: "directions_run",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "project-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(["Permissions.Project.ViewAll", 'Permissions.Project.ViewAll', 'Permissions.ProjectType.ViewAll', 'Permissions.Project.ViewAll']),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.HOME.LIST.DASHBOARD1",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "project.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/projectmanagement/projectdashboard/main",
                title: "MENUITEMS.PROJECTMANAGEMENT.LIST.PROJECTDASHBOARD",
                moduleName: "project",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "project.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
                claim: "Permissions.Project.ViewAll",
                favorite: false,
                submenu: [],
              },
            ],
          },
          //Project
          {
            path: "",
            title: "MENUITEMS.PROJECT.TEXT",
            moduleName: "project",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "project.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/projectmanagement/project/allprojects",
                title: "MENUITEMS.PROJECT.LIST.ALL-PROJECTS",
                moduleName: "project",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "project.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
                claim: "Permissions.Project.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/projectmanagement/project/addproject",
                title: "MENUITEMS.PROJECT.LIST.NEW-PROJECT",
                moduleName: "project",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-project.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Project.Create"),
                claim: "Permissions.Project.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //ProjectFollowUp
          {
            path: "",
            title: "MENUITEMS.PROJECTFOLLOWUP.TEXT",
            moduleName: "projectfollowup",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "project.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/projectmanagement/projectfollowup/allprojectsfollowup",
                title: "MENUITEMS.PROJECTFOLLOWUP.LIST.ALL-PROJECTSFOLLOWUP",
                moduleName: "projectfollowup",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "project.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
                claim: "Permissions.Project.ViewAll",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //ProjectType
          {
            path: "",
            title: "MENUITEMS.PROJECTTYPE.TEXT",
            moduleName: "projecttype",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "project.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ProjectType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/projectmanagement/projecttype/allprojecttypes",
                title: "MENUITEMS.PROJECTTYPE.LIST.ALL-PROJECTTYPES",
                moduleName: "projecttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "project.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProjectType.ViewAll"),
                claim: "Permissions.ProjectType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/projectmanagement/projecttype/addprojecttype",
                title: "MENUITEMS.PROJECTTYPE.LIST.NEW-PROJECTTYPE",
                moduleName: "projecttype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-project.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ProjectType.Create"),
                claim: "Permissions.ProjectType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          //ProjectReports
          {
            path: "",
            title: "MENUITEMS.PROJECTMANAGEMENT.PROJECTREPORTS",
            moduleName: "projectreports",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "project.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/projectmanagement/projectreports/projectreports",
                title: "MENUITEMS.PROJECTMANAGEMENT.LIST.PROJECTREPORTS",
                moduleName: "projectreports",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "project.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Project.ViewAll"),
                claim: "Permissions.Project.ViewAll",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      // EMPLOYEEMANAGEMENT
      {
        path: "",
        title: "MENUITEMS.EMPLOYEEMANAGEMENT.TEXT",
        moduleName: "employeemanagement",
        iconType: "material-icons-two-tone",
        icon: "dashboard",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "employee.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.EmployeeSetting.ViewAll']),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.DEPARTMENT.TEXT",
            moduleName: "department",
            iconType: "material-icons-two-tone",
            icon: "medical_services",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "department.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/employeemanagement/department/alldepartment",
                title: "MENUITEMS.DEPARTMENT.LIST.All-DEPARTMENT",
                moduleName: "department",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "department.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/department/adddepartment",
                title: "MENUITEMS.DEPARTMENT.LIST.NEW-DEPARTMENT",
                moduleName: "department",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-department.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.POSITION.TEXT",
            moduleName: "position",
            iconType: "material-icons-two-tone",
            icon: "medical_information",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "position.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/employeemanagement/position/allposition",
                title: "MENUITEMS.POSITION.LIST.All-POSITION",
                moduleName: "position",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "position.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/position/addposition",
                title: "MENUITEMS.POSITION.LIST.NEW-POSITION",
                moduleName: "position",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-position.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SECTION.TEXT",
            moduleName: "section",
            iconType: "material-icons-two-tone",
            icon: "bug_report",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "section.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/employeemanagement/section/allsection",
                title: "MENUITEMS.SECTION.LIST.All-SECTION",
                moduleName: "section",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "section.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/section/addsection",
                title: "MENUITEMS.SECTION.LIST.NEW-SECTION",
                moduleName: "section",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-section.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SPECIALIZATION.TEXT",
            moduleName: "specialization",
            iconType: "material-icons-two-tone",
            icon: "support_agent",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "specialization.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/employeemanagement/specialization/allspecialization",
                title: "MENUITEMS.SPECIALIZATION.LIST.All-SPECIALIZATION",
                moduleName: "specialization",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "specialization.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/specialization/addspecialization",
                title: "MENUITEMS.SPECIALIZATION.LIST.NEW-SPECIALIZATION",
                moduleName: "specialization",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-specialization.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.RELATIONSHIP.TEXT",
            moduleName: "relationship",
            iconType: "material-icons-two-tone",
            icon: "support_agent",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "relationship.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/employeemanagement/relationship/allrelationship",
                title: "MENUITEMS.RELATIONSHIP.LIST.All-RELATIONSHIP",
                moduleName: "relationship",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "relationship.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/relationship/addrelationship",
                title: "MENUITEMS.RELATIONSHIP.LIST.NEW-RELATIONSHIP",
                moduleName: "relationship",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-relationship.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Employee.TEXT",
            moduleName: "employee",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Employee.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Employee.ViewAll"),
            claim: "Permissions.Employee.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/employeemanagement/employee/allemployee",
                title: "MENUITEMS.Employee.LIST.All-Employee",
                moduleName: "Employee",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Employee.ViewAll"),
                claim: "Permissions.Employee.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/employee/addemployee",
                title: "MENUITEMS.Employee.LIST.NEW-Employee",
                moduleName: "Employee",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Employee.Create"),
                claim: "Permissions.Employee.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Nationality.TEXT",
            moduleName: "nationality",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Nationality.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            claim: "Permissions.EmployeeSetting.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/employeemanagement/nationality/allnationality",
                title: "MENUITEMS.Nationality.LIST.All-Nationality",
                moduleName: "Nationality",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/nationality/addnationality",
                title: "MENUITEMS.Nationality.LIST.NEW-Nationality",
                moduleName: "Nationality",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.MaritalStatus.TEXT",
            moduleName: "maritalstatus",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "maritalstatus.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/employeemanagement/maritalstatus/allmaritalstatus",
                title: "MENUITEMS.MaritalStatus.LIST.All-MaritalStatus",
                moduleName: "maritalstatus",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/employeemanagement/maritalstatus/addmaritalstatus",
                title: "MENUITEMS.MaritalStatus.LIST.NEW-MaritalStatus",
                moduleName: "maritalstatus",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EducationDegree.TEXT",
            moduleName: "educationdegree",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "EducationDegree.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            claim: "Permissions.EmployeeSetting.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/employeemanagement/educationdegree/alleducationdegree",
                title: "MENUITEMS.EducationDegree.LIST.All-EducationDegree",
                moduleName: "EducationDegree",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/educationdegree/addeducationdegree",
                title: "MENUITEMS.EducationDegree.LIST.NEW-EducationDegree",
                moduleName: "EducationDegree",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Job.TEXT",
            moduleName: "job",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Job.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            claim: "Permissions.EmployeeSetting.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/employeemanagement/job/alljob",
                title: "MENUITEMS.Job.LIST.All-Job",
                moduleName: "Job",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/job/addjob",
                title: "MENUITEMS.Job.LIST.NEW-Job",
                moduleName: "Job",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.CourtesyTitle.TEXT",
            moduleName: "courtesytitle",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "CourtesyTitle.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
            claim: "Permissions.EmployeeSetting.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/employeemanagement/courtesytitle/allcourtesytitle",
                title: "MENUITEMS.CourtesyTitle.LIST.All-CourtesyTitle",
                moduleName: "CourtesyTitle",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.ViewAll"),
                claim: "Permissions.EmployeeSetting.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/employeemanagement/courtesytitle/addcourtesytitle",
                title: "MENUITEMS.CourtesyTitle.LIST.NEW-CourtesyTitle",
                moduleName: "CourtesyTitle",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EmployeeSetting.Create"),
                claim: "Permissions.EmployeeSetting.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ]
      },
      //Attendance
      {
        path: "",
        title: "MENUITEMS.ATTENDANCE.TEXT",
        moduleName: "attendance",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "home.svg",
        role: ["All"],
        displayPermission: true,
        submenu: [
          {
            path: "/admin/attendance/allattendancecheckinouttype",
            title: "MENUITEMS.ATTENDANCECHECKINOUTTYPE.LIST.All-ATTENDANCECHECKINOUTTYPE",
            moduleName: "attendance",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "dashboard.svg",
            role: ['All'],
            displayPermission: this.permissionService.IsAuthorized("Permissions.AttendanceCheckInOutType.ViewAll"),
            claim: "Permissions.AttendanceCheckInOutType.ViewAll",
            submenu: [],
          },
          {
            path: "/admin/attendance/allattendancelocation",
            title: "MENUITEMS.ATTENDANCELOCATION.LIST.All-ATTENDANCELOCATION",
            moduleName: "attendance",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "dashboard.svg",
            role: ['All'],
            displayPermission: this.permissionService.IsAuthorized("Permissions.AttendanceLocation.ViewAll"),
            claim: "Permissions.AttendanceLocation.ViewAll",
            submenu: [],
          },
          {
            path: "/admin/attendance/allattendanceshift",
            title: "MENUITEMS.ATTENDANCESHIFT.LIST.All-ATTENDANCESHIFT",
            moduleName: "attendance",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "dashboard.svg",
            role: ['All'],
            displayPermission: this.permissionService.IsAuthorized("Permissions.AttendanceShift.ViewAll"),
            claim: "Permissions.AttendanceShift.ViewAll",
            submenu: [],
          },
          {
            path: "/admin/attendance/allattendanceschedule",
            title: "MENUITEMS.ATTENDANCESCHEDULE.LIST.All-ATTENDANCESCHEDULE",
            moduleName: "attendance",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "dashboard.svg",
            role: ['All'],
            displayPermission: this.permissionService.IsAuthorized("Permissions.AttendanceSchedule.ViewAll"),
            claim: "Permissions.AttendanceSchedule.ViewAll",
            submenu: [],
          },

          // {
          //   path: "/admin/dashboard/seatsnew",
          //   title: "MENUITEMS.HOME.LIST.Seats",
          //   moduleName: "dashboard",
          //   iconType: "material-icons-two-tone",
          //   icon: "",
          //   class: "ml-menu",
          //   groupTitle: false,
          //   iconPath: "menus.svg",
          //   role: ['All'],
          //   displayPermission: true,
          //   submenu: [],
          // },
          // {
          //   path: "/admin/dashboard/updatehistory",
          //   title: "COMMON.Updates",
          //   moduleName: "dashboard",
          //   iconType: "material-icons-two-tone",
          //   icon: "",
          //   class: "ml-menu",
          //   groupTitle: false,
          //   iconPath: "update-history.svg",
          //   role: ['All'],
          //   displayPermission: true,
          //   submenu: [],
          // }
        ],
      },
      //Management
      {
        path: "",
        title: "MENUITEMS.USERMANAGEMENT.TEXT",
        moduleName: "usermanagement",
        iconType: "material-icons-two-tone",
        icon: "supervisor_account",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "user-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Users.ViewAll', 'Permissions.Roles.ViewAll', 'Permissions.RoleClaims.ViewAll', "Permissions.ApprovalTemplate.ViewAll"]),
        submenu: [
          {
            path: "",
            title: "MENUITEMS.USERS.TEXT",
            moduleName: "user",
            iconType: "material-icons-two-tone",
            icon: "person",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "users.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Users.ViewAll', 'Permissions.Users.Create']),

            submenu: [
              {
                path: "/admin/usermanagement/user/all-users",
                title: "MENUITEMS.USERS.LIST.ALL-USERS",
                moduleName: "user",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Users.ViewAll"),
                claim: "Permissions.Users.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/usermanagement/user/add-user",
                title: "MENUITEMS.USERS.LIST.NEW-USER",
                moduleName: "user",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Users.Create"),
                claim: "Permissions.Users.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ROLES.TEXT",
            moduleName: "role",
            iconType: "material-icons-two-tone",
            icon: "supervisor_account",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "roles.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Roles.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/usermanagement/role/all-roles",
                title: "MENUITEMS.ROLES.LIST.ALL-ROLES",
                moduleName: "role",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Roles.ViewAll"),
                claim: "Permissions.Roles.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/usermanagement/role/add-role",
                title: "MENUITEMS.ROLES.LIST.NEW-ROLE",
                moduleName: "role",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Roles.Create"),
                claim: "Permissions.Roles.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.CLAIM.TEXT",
            moduleName: "claim",
            iconType: "material-icons-two-tone",
            icon: "shield",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "claim.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.RoleClaims.View"),
            favorite: false,
            submenu: [
              {
                path: "/admin/usermanagement/claim/allclaim",
                title: "MENUITEMS.CLAIM.LIST.ALL-CLAIM",
                moduleName: "claim",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RoleClaims.View"),
                claim: "Permissions.RoleClaims.View",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/usermanagement/claim/addclaim",
                title: "MENUITEMS.CLAIM.LIST.NEW-CLAIM",
                moduleName: "claim",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RoleClaims.Create"),
                claim: "Permissions.RoleClaims.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ApprovalTemplate.TEXT",
            moduleName: "approvaltemplate",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "receipt-voucher.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ApprovalTemplate.ViewAll"),
            submenu: [
              {
                path: "/admin/usermanagement/approvaltemplate/allapprovaltemplate",
                title: "MENUITEMS.ApprovalTemplate.LIST.All-ApprovalTemplate",
                moduleName: "approvaltemplate",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ApprovalTemplate.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/usermanagement/approvaltemplate/addapprovaltemplate",
                title: "MENUITEMS.ApprovalTemplate.LIST.NEW-ApprovalTemplate",
                moduleName: "approvaltemplate",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ApprovalTemplate.Create"),
                submenu: [],
              }
            ],
          },
        ]
      },
      //devices
      {
        path: "",
        title: "MENUITEMS.DEVICES.TEXT",
        moduleName: "devices",
        iconType: "material-icons-two-tone",
        icon: "supervisor_account",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "devices.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Device.ViewAll', 'Permissions.DeviceType.ViewAll', 'Permissions.DeviceModel.ViewAll', '"Permissions.Printer.ViewAll']),
        submenu: [
          {
            path: "",
            title: "MENUITEMS.DEVICETYPE.TEXT",
            moduleName: "devicetype",
            iconType: "material-icons-two-tone",
            icon: "devices_other",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "devicetype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.DeviceType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/devices/devicetype/alldevicetype",
                title: "MENUITEMS.DEVICETYPE.LIST.All-DEVICETYPE",
                moduleName: "devicetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeviceType.ViewAll"),
                claim: "Permissions.DeviceType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/devices/devicetype/adddevicetype",
                title: "MENUITEMS.DEVICETYPE.LIST.NEW-DEVICETYPE",
                moduleName: "devicetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeviceType.Create"),
                claim: "Permissions.DeviceType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.DEVICEMODEL.TEXT",
            moduleName: "devicemodel",
            iconType: "material-icons-two-tone",
            icon: "flag",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "devicemodel.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.DeviceModel.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/devices/devicemodel/alldevicemodel",
                title: "MENUITEMS.DEVICEMODEL.LIST.All-DEVICEMODEL",
                moduleName: "devicemodel",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeviceModel.ViewAll"),
                claim: "Permissions.DeviceModel.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/devices/devicemodel/adddevicemodel",
                title: "MENUITEMS.DEVICEMODEL.LIST.NEW-DEVICEMODEL",
                moduleName: "devicemodel",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.DeviceModel.Create"),
                claim: "Permissions.DeviceModel.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.DEVICE.TEXT",
            moduleName: "device",
            iconType: "material-icons-two-tone",
            icon: "devices",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "device.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Device.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/devices/device/alldevice",
                title: "MENUITEMS.DEVICE.LIST.All-DEVICE",
                moduleName: "device",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Device.ViewAll"),
                claim: "Permissions.Device.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/devices/device/adddevice",
                title: "MENUITEMS.DEVICE.LIST.NEW-DEVICE",
                moduleName: "device",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Device.Create"),
                claim: "Permissions.Device.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PRINTER.TEXT",
            moduleName: "printer",
            iconType: "material-icons-two-tone",
            icon: "print",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "printer.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Printer.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/devices/printer/allprinter",
                title: "MENUITEMS.PRINTER.LIST.All-PRINTER",
                moduleName: "printer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Printer.ViewAll"),
                claim: "Permissions.Printer.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/devices/printer/addprinter",
                title: "MENUITEMS.PRINTER.LIST.NEW-PRINTER",
                moduleName: "printer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Printer.Create"),
                claim: "Permissions.Printer.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.BARCODESCALE.TEXT",
            moduleName: "barcodescale",
            iconType: "material-icons-two-tone",
            icon: "barcodescale",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "barcode-scale.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Device.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/devices/barcodescale/allbarcodescale",
                title: "MENUITEMS.BARCODESCALE.LIST.All-BARCODESCALE",
                moduleName: "barcodescale",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "barcode-scale.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Device.ViewAll"),
                claim: "Permissions.Device.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/devices/barcodescale/addbarcodescale",
                title: "MENUITEMS.BARCODESCALE.LIST.NEW-BARCODESCALE",
                moduleName: "barcodescale",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-barcode-scale.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Device.Create"),
                claim: "Permissions.Device.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ]
      },
      //Loyalty
      {
        path: "",
        title: "MENUITEMS.LOYALTY.TEXT",
        moduleName: "loyalty",
        iconType: "material-icons-two-tone",
        icon: "supervisor_account",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.LoyaltyCategory.ViewAll']),
        submenu: [
          {
            path: "",
            title: "MENUITEMS.LOYALTYCATEGORY.TEXT",
            moduleName: "loyaltycategory",
            iconType: "material-icons-two-tone",
            icon: "request_quote",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "delivery-charge.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.LoyaltyCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/settings/loyaltycategory/allloyaltycategory",
                title: "MENUITEMS.LOYALTYCATEGORY.LIST.ALL-LOYALTYCATEGORY",
                moduleName: "loyaltycategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "delivery-charge.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.LoyaltyCategory.ViewAll"),
                claim: "Permissions.LoyaltyCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/settings/loyaltycategory/addloyaltycategory",
                title: "MENUITEMS.LOYALTYCATEGORY.LIST.NEW-LOYALTYCATEGORY",
                moduleName: "loyaltycategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-delivery-charge.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.LoyaltyCategory.Create"),
                claim: "Permissions.LoyaltyCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ]
      },
      //AREAMANAGEMENT
      {
        path: "",
        title: "MENUITEMS.AREAMANAGEMENT.TEXT",
        moduleName: "geographicalarea",
        iconType: "material-icons-two-tone",
        icon: "public",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "geographic-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Country.ViewAll', 'Permissions.City.ViewAll', 'Permissions.District.ViewAll', 'Permissions.DeliveryZone.ViewAll', 'Permissions.Branch.ViewAll']),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.COUNTRIES.TEXT",
            moduleName: "country",
            iconType: "material-icons-two-tone",
            icon: "flag",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "country.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Country.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/geographicalarea/country/allcountryies",
                title: "MENUITEMS.COUNTRIES.LIST.All-COUNTRIES",
                moduleName: "country",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Country.ViewAll"),
                claim: "Permissions.Country.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/geographicalarea/country/addcountry",
                title: "MENUITEMS.COUNTRIES.LIST.NEW-COUNTRY",
                moduleName: "country",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",

                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Country.Create"),
                claim: "Permissions.Country.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.CITIES.TEXT",
            moduleName: "city",
            iconType: "material-icons-two-tone",
            icon: "location_city",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "city.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.City.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/geographicalarea/city/all-cities",
                title: "MENUITEMS.CITIES.LIST.All-CITIES",
                moduleName: "city",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.City.ViewAll"),
                claim: "Permissions.City.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/geographicalarea/city/add-city",
                title: "MENUITEMS.CITIES.LIST.NEW-CITIES",
                moduleName: "city",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.City.Create"),
                claim: "Permissions.City.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.DISTRICT.TEXT",
            moduleName: "district",
            iconType: "material-icons-two-tone",
            icon: "location_on",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "district.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.District.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/geographicalarea/district/alldistrict",
                title: "MENUITEMS.DISTRICT.LIST.All-DISTRICT",
                moduleName: "district",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.District.ViewAll"),
                claim: "Permissions.District.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/geographicalarea/district/adddistrict",
                title: "MENUITEMS.DISTRICT.LIST.NEW-DISTRICT",
                moduleName: "district",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.District.Create"),
                claim: "Permissions.District.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ADDRESSTYPES.TEXT",
            moduleName: "addresstype",
            iconType: "material-icons-two-tone",
            icon: "add_location_alt",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "addresstype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.AddressType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/geographicalarea/addresstype/alladdresstypes",
                title: "MENUITEMS.ADDRESSTYPES.LIST.ALL-ADDRESSTYPES",
                moduleName: "addresstype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "addresstype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.AddressType.ViewAll"),
                claim: "Permissions.AddressType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/geographicalarea/addresstype/addaddresstype",
                title: "MENUITEMS.ADDRESSTYPES.LIST.NEW-ADDRESSTYPE",
                moduleName: "addresstype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-addresstype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.AddressType.Create"),
                claim: "Permissions.AddressType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.BRANCHES.TEXT",
            moduleName: "branch",
            iconType: "material-icons-two-tone",
            icon: "business",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "branches.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Branch.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/geographicalarea/branch/allbranches",
                title: "MENUITEMS.BRANCHES.LIST.All-BRANCHES",
                moduleName: "branch",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "branch.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Branch.ViewAll"),
                claim: "Permissions.Branch.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/geographicalarea/branch/addbranch",
                title: "MENUITEMS.BRANCHES.LIST.NEW-BRANCH",
                moduleName: "branch",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-branch.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Branch.Create"),
                claim: "Permissions.Branch.Create",
                favorite: false,
                submenu: [],
              }
            ],
          }
        ]
      },
      // SUPSCRIPTION
      {
        path: "",
        title: "MENUITEMS.SUPSCRIPTION.TEXT",
        moduleName: "subscription",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "subscriptions.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorized("Permissions.Suppliers.Create"),
        claim: "Permissions.Suppliers.Create",
        favorite: false,
        submenu: [
          {
            path: "",
            title: "MENUITEMS.SUBSCRIPTIONFEATURE.TEXT",
            moduleName: "subscriptionfeature",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "subscriptionplan.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionFeatures.ViewAll"),
            submenu: [

              {
                path: "/admin/subscription/subscriptionfeature/allsubscriptionfeature",
                title: "MENUITEMS.SUBSCRIPTIONFEATURE.LIST.All-SUBSCRIPTIONFEATURE",
                moduleName: "SubscriptionFeature",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "subscriptionplan.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionFeatures.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/subscription/subscriptionfeature/addsubscriptionfeature",
                title: "MENUITEMS.SUBSCRIPTIONFEATURE.LIST.NEW-SUBSCRIPTIONFEATURE",
                moduleName: "SubscriptionFeature",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-subscriptionplan.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionFeatures.Create"),
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SUPSCRIPTIONPACKAGES.TEXT",
            moduleName: "subscriptionPackages",
            iconType: "material-icons-two-tone",
            icon: "redeem",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "subscriptionplan.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [
              {
                path: "/admin/subscription/subscriptionPackages/allsubscriptionPackages",
                title: "MENUITEMS.SUPSCRIPTIONPACKAGES.LIST.All-SUPSCRIPTIONPACKAGES",
                moduleName: "subscriptionPackages",
                iconType: "material-icons-two-tone",
                icon: "redeem",
                // card_membership
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "subscriptionplan.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionPackages.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/subscription/subscriptionPackages/addsubscriptionPackages",
                title: "MENUITEMS.SUPSCRIPTIONPACKAGES.LIST.NEW-SUPSCRIPTIONPACKAGES",
                moduleName: "subscriptionPackages",
                iconType: "material-icons-two-tone",
                icon: "add",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-subscriptionplan.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionPackages.Create"),
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SUBSCRIPTIONPLANS.TEXT",
            moduleName: "subscriptionplans",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "subscriptionplan.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/subscription/subscriptionplans/allsubscriptionplans",
                title: "MENUITEMS.SUBSCRIPTIONPLANS.LIST.All-SUBSCRIPTIONPLANS",
                moduleName: "SubscriptionPlans",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "subscriptionplan.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionPlans.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/subscription/subscriptionplans/addsubscriptionplans",
                title: "MENUITEMS.SUBSCRIPTIONPLANS.LIST.NEW-SUBSCRIPTIONPLANS",
                moduleName: "SubscriptionPlans",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-subscriptionplan.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionPlans.Create"),
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SUBSCRIPTIONMANAGEMENT.TEXT",
            moduleName: "scriptionmanagement",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "subscriptions.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/subscription/scriptionmanagement/allscriptionmanagement",
                title: "MENUITEMS.SUBSCRIPTIONMANAGEMENT.LIST.All-SUBSCRIPTIONMANAGEMENT",
                moduleName: "scriptionmanagement",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "subscriptions.svg",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SHAREDDBCONNECTIONSTRINGS.TEXT",
            moduleName: "shareddbconnectionstrings",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "shareddbconnectionstrings.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionPlans.ViewAll"),
            submenu: [
              {
                path: "/admin/subscription/shareddbconnectionstrings/allshareddbconnectionstrings",
                title: "MENUITEMS.SHAREDDBCONNECTIONSTRINGS.LIST.All-SHAREDDBCONNECTIONSTRINGS",
                moduleName: "shareddbconnectionstrings",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "shareddbconnectionstrings.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionPlans.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/subscription/shareddbconnectionstrings/addshareddbconnectionstrings",
                title: "MENUITEMS.SHAREDDBCONNECTIONSTRINGS.LIST.NEW-SHAREDDBCONNECTIONSTRINGS",
                moduleName: "shareddbconnectionstrings",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-shareddbconnectionstrings.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SubscriptionPlans.Create"),
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TENANTS.TEXT",
            moduleName: "tenants",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "tenants.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tenants.ViewAll"),
            submenu: [
              {
                path: "/admin/subscription/tenants/alltenants",
                title: "MENUITEMS.TENANTS.LIST.All-TENANTS",
                moduleName: "tenants",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "tenants.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tenants.ViewAll"),
                submenu: [],
              },
              {
                path: "/admin/subscription/tenants/addtenants",
                title: "MENUITEMS.TENANTS.LIST.NEW-TENANTS",
                moduleName: "tenants",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-tenants.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tenants.Create"),
                submenu: [],
              }
            ],
          },
        ],
      },
      //NOTIFICATIONS
      {
        path: "",
        title: "MENUITEMS.NOTIFICATIONS.TEXT",
        moduleName: "notifications",
        iconType: "material-icons-two-tone",
        icon: "supervisor_account",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "notification-settings.svg",
        role: ["All"],
        displayPermission: true,
        // displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Users.ViewAll']),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.NOTIFICATION.TEXT",
            moduleName: "notification",
            iconType: "material-icons-two-tone",
            icon: "person",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "notifications.svg",
            role: ["All"],
            displayPermission: true,
            // displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Users.ViewAll', 'Permissions.Users.Create']),

            submenu: [
              {
                path: "/admin/notifications/notification/allnotification",
                title: "MENUITEMS.NOTIFICATION.LIST.All-NOTIFICATION",
                moduleName: "notification",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "notifications.svg",
                role: [""],
                displayPermission: true,

                // displayPermission: this.permissionService.IsAuthorized("Permissions.Users.ViewAll"),
                claim: "Permissions.Users.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/notifications/notification/addnotification",
                title: "MENUITEMS.NOTIFICATION.LIST.NEW-NOTIFICATION",
                moduleName: "notification",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-notification.svg",
                role: [""],
                displayPermission: true,

                // displayPermission: this.permissionService.IsAuthorized("Permissions.Users.Create"),
                claim: "Permissions.Users.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.NOTIFICATIONTEMPLATE.TEXT",
            moduleName: "notificationtemplate",
            iconType: "material-icons-two-tone",
            icon: "person",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "notification-template.svg",
            role: ["All"],
            displayPermission: true,
            // displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Users.ViewAll', 'Permissions.Users.Create']),

            submenu: [
              {
                path: "/admin/notifications/notificationtemplate/allnotificationtemplate",
                title: "MENUITEMS.NOTIFICATIONTEMPLATE.LIST.All-NOTIFICATIONTEMPLATE",
                moduleName: "notificationtemplate",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "notification-template.svg",
                role: [""],
                displayPermission: true,

                // displayPermission: this.permissionService.IsAuthorized("Permissions.Users.ViewAll"),
                claim: "Permissions.Users.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/notifications/notificationtemplate/addnotificationtemplate",
                title: "MENUITEMS.NOTIFICATIONTEMPLATE.LIST.NEW-NOTIFICATIONTEMPLATE",
                moduleName: "notificationtemplate",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-notification-template.svg",
                role: [""],
                displayPermission: true,

                // displayPermission: this.permissionService.IsAuthorized("Permissions.Users.Create"),
                claim: "Permissions.Users.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.NOTIFICATIONTOPIC.TEXT",
            moduleName: "notificationtopic",
            iconType: "material-icons-two-tone",
            icon: "person",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "notification-topic.svg",
            role: ["All"],
            displayPermission: true,
            // displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Users.ViewAll', 'Permissions.Users.Create']),

            submenu: [
              {
                path: "/admin/notifications/notificationtopic/allnotificationtopic",
                title: "MENUITEMS.NOTIFICATIONTOPIC.LIST.All-NOTIFICATIONTOPIC",
                moduleName: "notificationtopic",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "notification-topic.svg",
                role: [""],
                displayPermission: true,

                // displayPermission: this.permissionService.IsAuthorized("Permissions.Users.ViewAll"),
                claim: "Permissions.Users.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/notifications/notificationtopic/addnotificationtopic",
                title: "MENUITEMS.NOTIFICATIONTOPIC.LIST.NEW-NOTIFICATIONTOPIC",
                moduleName: "notificationtopic",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-notification-topic.svg",
                role: [""],
                displayPermission: true,

                // displayPermission: this.permissionService.IsAuthorized("Permissions.Users.Create"),
                claim: "Permissions.Users.Create",
                favorite: false,
                submenu: [],
              }
            ],
          }
        ]
      },
      // WEBSITE
      {
        path: "",
        title: "MENUITEMS.WEBSITE.TEXT",
        moduleName: "website",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "website-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(["Permissions.Activity.ViewAll", "Permissions.Facility.ViewAll"]),

        submenu: [
          //activity
          {
            path: "",
            title: "MENUITEMS.ACTIVITY.TEXT",
            moduleName: "activity",
            iconType: "material-icons-two-tone",
            icon: "format_underlined",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "activity.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Activity.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/website/activity/allactivities",
                title: "MENUITEMS.ACTIVITY.LIST.All-ACTIVITIES",
                moduleName: "activity",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Activity.ViewAll"),
                claim: "Permissions.Activity.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/website/activity/addactivity",
                title: "MENUITEMS.ACTIVITY.LIST.NEW-activity",
                moduleName: "activity",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "dashboard.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Activity.Create"),
                claim: "Permissions.Activity.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          // FACILITIES
          {
            path: "",
            title: "MENUITEMS.FACILITIES.TEXT",
            moduleName: "facility",
            iconType: "material-icons-two-tone",
            icon: "business",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "facility.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Facility.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/website/facility/allfacilities",
                title: "MENUITEMS.FACILITIES.LIST.ALL-FACILITIES",
                moduleName: "facility",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "facility.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Facility.ViewAll"),
                claim: "Permissions.Facility.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/website/facility/addfacility",
                title: "MENUITEMS.FACILITIES.LIST.NEW-FACILITY",
                moduleName: "facility",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-facility.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Facility.Create"),
                claim: "Permissions.Facility.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Page.TEXT",
            moduleName: "page",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "page.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Pages.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/website/page/allpage",
                title: "MENUITEMS.Page.LIST.All-Page",
                moduleName: "page",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "page.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Pages.ViewAll"),
                claim: "Permissions.Pages.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/website/page/addpage",
                title: "MENUITEMS.Page.LIST.NEW-Page",
                moduleName: "page",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-page.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Pages.Create"),
                claim: "Permissions.Pages.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.OurPartner.TEXT",
            moduleName: "ourpartner",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "OurPartner.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.OurPartner.ViewAll"),
            claim: "Permissions.OurPartner.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/website/ourpartner/allourpartner",
                title: "MENUITEMS.OurPartner.LIST.All-OurPartner",
                moduleName: "OurPartner",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "OurPartner.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.OurPartner.ViewAll"),
                claim: "Permissions.OurPartner.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/website/ourpartner/addourpartner",
                title: "MENUITEMS.OurPartner.LIST.NEW-OurPartner",
                moduleName: "OurPartner",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "OurPartner.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.OurPartner.Create"),
                claim: "Permissions.OurPartner.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      //TECHNICALSUPPORT
      {
        path: "",
        title: "MENUITEMS.TECHNICALSUPPORT.TEXT",
        moduleName: "technicalsupport",
        iconType: "material-icons-two-tone",
        icon: "dashboard",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "technical-support-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Tickets.ViewAll', 'Permissions.Service.ViewAll', 'Permissions.ServiceType.ViewAll', 'Permissions.SupportDepartment.ViewAll', 'Permissions.IssueType.ViewAll']),

        submenu: [
          {
            path: "/admin/technicalsupport/ticketdashboard/ticketdashboard",
            title: "MENUITEMS.TICKETDASHBOARD.TEXT",
            moduleName: "ticketdashboard",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            iconPath: "dashboard-Ticket.svg",
            role: [""],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tickets.ViewAll"),
            claim: "Permissions.Tickets.ViewAll",
            favorite: false,
            submenu: [],
          },
          {
            path: "",
            title: "MENUITEMS.TICKET.TEXT",
            moduleName: "ticket",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Ticket-ico.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tickes.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/technicalsupport/ticket/allticket",
                title: "MENUITEMS.TICKET.LIST.All-TICKET",
                moduleName: "ticket",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "Ticket-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tickes.ViewAll"),
                claim: "Permissions.Tickes.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/technicalsupport/ticket/addticket",
                title: "MENUITEMS.TICKET.LIST.NEW-TICKET",
                moduleName: "ticket",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-Ticket-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tickes.Create"),
                claim: "Permissions.Tickes.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TICKETSTATUSFOLLOWUP.TEXT",
            moduleName: "ticketstatusfollowup",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Ticket-Status.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tickes.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/technicalsupport/ticketstatusfollowup/allticketstatusfollowup",
                title: "MENUITEMS.TICKETSTATUSFOLLOWUP.LIST.All-TICKETSTATUSFOLLOWUP",
                moduleName: "ticketstatusfollowup",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "Ticket-Status.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Tickes.ViewAll"),
                claim: "Permissions.Tickes.ViewAll",
                favorite: false,
                submenu: [],
              },

            ],
          },
          {
            path: "",
            title: "MENUITEMS.SERVICE.TEXT",
            moduleName: "service",
            iconType: "material-icons-two-tone",
            icon: "medical_services",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Service-ico.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Service.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/technicalsupport/service/allservice",
                title: "MENUITEMS.SERVICE.LIST.All-SERVICE",
                moduleName: "service",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "Service-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Service.ViewAll"),
                claim: "Permissions.Service.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/technicalsupport/service/addservice",
                title: "MENUITEMS.SERVICE.LIST.NEW-SERVICE",
                moduleName: "service",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-Service-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Service.Create"),
                claim: "Permissions.Service.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SERVICETYPE.TEXT",
            moduleName: "servicetype",
            iconType: "material-icons-two-tone",
            icon: "medical_information",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "service-type.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ServiceType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/technicalsupport/servicetype/allservicetype",
                title: "MENUITEMS.SERVICETYPE.LIST.All-SERVICETYPE",
                moduleName: "servicetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "service-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ServiceType.ViewAll"),
                claim: "Permissions.ServiceType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/technicalsupport/servicetype/addservicetype",
                title: "MENUITEMS.SERVICETYPE.LIST.NEW-SERVICETYPE",
                moduleName: "servicetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-service-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ServiceType.Create"),
                claim: "Permissions.ServiceType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ISSUETYPE.TEXT",
            moduleName: "issuetype",
            iconType: "material-icons-two-tone",
            icon: "bug_report",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "issue-type.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.IssueType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/technicalsupport/issuetype/allissuetype",
                title: "MENUITEMS.ISSUETYPE.LIST.All-ISSUETYPE",
                moduleName: "issuetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "issue-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.IssueType.ViewAll"),
                claim: "Permissions.IssueType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/technicalsupport/issuetype/addissuetype",
                title: "MENUITEMS.ISSUETYPE.LIST.NEW-ISSUETYPE",
                moduleName: "issuetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-issue-type.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.IssueType.Create"),
                claim: "Permissions.IssueType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SUPPORTDEPARTMENT.TEXT",
            moduleName: "supportdepartment",
            iconType: "material-icons-two-tone",
            icon: "support_agent",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "support-management.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.SupportDepartment.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/technicalsupport/supportdepartment/allsupportdepartment",
                title: "MENUITEMS.SUPPORTDEPARTMENT.LIST.All-SUPPORTDEPARTMENT",
                moduleName: "supportdepartment",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "support-management.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SupportDepartment.ViewAll"),
                claim: "Permissions.SupportDepartment.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/technicalsupport/supportdepartment/addsupportdepartment",
                title: "MENUITEMS.SUPPORTDEPARTMENT.LIST.NEW-SUPPORTDEPARTMENT",
                moduleName: "supportdepartment",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-support-management.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SupportDepartment.Create"),
                claim: "Permissions.SupportDepartment.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },

        ],
      },
      // EventManagement
      {
        path: "",
        title: "MENUITEMS.EVENTMANAGEMENT.TEXT",
        moduleName: "eventmanagement",
        iconType: "material-icons-two-tone",
        icon: "dashboard",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "eventmanagement.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Events.ViewAll', 'Permissions.EventSiteType.ViewAll', 'Permissions.EventCategory.ViewAll', 'Permissions.EventSite.ViewAll', 'Permissions.EventTicketType.ViewAll', 'Permissions.EventTickets.ViewAll']),

        submenu: [
          {
            path: "/admin/eventmanagement/eventdashboard/eventdashboard",
            title: "MENUITEMS.EVENTDASHBOARD.TEXT",
            moduleName: "eventdashboard",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            iconPath: "dashboard-Ticket.svg",
            role: [""],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Events.ViewAll"),
            claim: "Permissions.Events.ViewAll",
            favorite: false,
            submenu: [],
          },
          {
            path: "",
            title: "MENUITEMS.EVENT.TEXT",
            moduleName: "event",
            iconType: "material-icons-two-tone",
            icon: "medical_services",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "eventmanagement.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Events.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/eventmanagement/event/allevent",
                title: "MENUITEMS.EVENT.LIST.All-EVENT",
                moduleName: "event",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "eventmanagement.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Events.ViewAll"),
                claim: "Permissions.Events.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/event/addevent",
                title: "MENUITEMS.EVENT.LIST.NEW-EVENT",
                moduleName: "event",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-eventmanagement.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Events.Create"),
                claim: "Permissions.Events.Create",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/event/eventreview",
                title: "MENUITEMS.EVENT.LIST.EVENT-REVIEW",
                moduleName: "event",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Events.ViewAll"),
                claim: "Permissions.Events.ViewAll",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EVENTSITETYPE.TEXT",
            moduleName: "eventsitetype",
            iconType: "material-icons-two-tone",
            icon: "medical_information",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "eventsitetype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventSiteType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/eventmanagement/eventsitetype/alleventsitetype",
                title: "MENUITEMS.EVENTSITETYPE.LIST.All-EVENTSITETYPE",
                moduleName: "eventsitetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "eventsitetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventSiteType.ViewAll"),
                claim: "Permissions.EventSiteType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/eventsitetype/addeventsitetype",
                title: "MENUITEMS.EVENTSITETYPE.LIST.NEW-EVENTSITETYPE",
                moduleName: "eventsitetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-eventsitetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventSiteType.Create"),
                claim: "Permissions.EventSiteType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EVENTSITETYPECATEGORY.TEXT",
            moduleName: "eventsitetypecategory",
            iconType: "material-icons-two-tone",
            icon: "bug_report",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "eventsitetypecategory.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/eventmanagement/eventsitetypecategory/alleventsitetypecategory",
                title: "MENUITEMS.EVENTSITETYPECATEGORY.LIST.All-EVENTSITETYPECATEGORY",
                moduleName: "eventsitetypecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "eventsitetypecategory.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventCategory.ViewAll"),
                claim: "Permissions.EventCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/eventsitetypecategory/addeventsitetypecategory",
                title: "MENUITEMS.EVENTSITETYPECATEGORY.LIST.NEW-EVENTSITETYPECATEGORY",
                moduleName: "eventsitetypecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-eventsitetypecategory.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventCategory.Create"),
                claim: "Permissions.EventCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EVENTSITE.TEXT",
            moduleName: "eventsite",
            iconType: "material-icons-two-tone",
            icon: "support_agent",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "eventsitetype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventSite.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/eventmanagement/eventsite/alleventsite",
                title: "MENUITEMS.EVENTSITE.LIST.All-EVENTSITE",
                moduleName: "eventsite",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "eventsitetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventSite.ViewAll"),
                claim: "Permissions.EventSite.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/eventsite/addeventsite",
                title: "MENUITEMS.EVENTSITE.LIST.NEW-EVENTSITE",
                moduleName: "eventsite",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-eventsitetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventSite.Create"),
                claim: "Permissions.EventSite.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TICKETTYPE.TEXT",
            moduleName: "tickettype",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Ticket.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventTicketType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/eventmanagement/tickettype/alltickettype",
                title: "MENUITEMS.TICKETTYPE.LIST.All-TICKETTYPE",
                moduleName: "tickettype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "Ticket.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventTicketType.ViewAll"),
                claim: "Permissions.EventTicketType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/tickettype/addtickettype",
                title: "MENUITEMS.TICKETTYPE.LIST.NEW-TICKETTYPE",
                moduleName: "tickettype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-Ticket.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventTicketType.Create"),
                claim: "Permissions.EventTicketType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EVENTTICKET.TEXT",
            moduleName: "eventticket",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "eventticket.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventTickets.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/eventmanagement/eventticket/alleventticket",
                title: "MENUITEMS.EVENTTICKET.LIST.All-EVENTTICKET",
                moduleName: "eventticket",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "eventticket.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventTickets.ViewAll"),
                claim: "Permissions.EventTickets.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/eventticket/addeventticket",
                title: "MENUITEMS.EVENTTICKET.LIST.NEW-EVENTTICKET",
                moduleName: "eventticket",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-eventticket.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventTickets.Create"),
                claim: "Permissions.EventTickets.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EventCategory.TEXT",
            moduleName: "eventcategory",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "EventCategory.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventCategory.ViewAll"),
            claim: "Permissions.EventCategory.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/eventmanagement/eventcategory/alleventcategory",
                title: "MENUITEMS.EventCategory.LIST.All-EventCategory",
                moduleName: "EventCategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventCategory.ViewAll"),
                claim: "Permissions.EventCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/eventcategory/addeventcategory",
                title: "MENUITEMS.EventCategory.LIST.NEW-EventCategory",
                moduleName: "EventCategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventCategory.Create"),
                claim: "Permissions.EventCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EventType.TEXT",
            moduleName: "eventtype",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "EventType.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventType.ViewAll"),
            claim: "Permissions.EventType.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/eventmanagement/eventtype/alleventtype",
                title: "MENUITEMS.EventType.LIST.All-EventType",
                moduleName: "EventType",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventType.ViewAll"),
                claim: "Permissions.EventType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/eventtype/addeventtype",
                title: "MENUITEMS.EventType.LIST.NEW-EventType",
                moduleName: "EventType",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventType.Create"),
                claim: "Permissions.EventType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.EventOrganizer.TEXT",
            moduleName: "eventorganizer",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "EventOrganizer.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.EventOrganizer.ViewAll"),
            claim: "Permissions.EventOrganizer.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/eventmanagement/eventorganizer/alleventorganizer",
                title: "MENUITEMS.EventOrganizer.LIST.All-EventOrganizer",
                moduleName: "EventOrganizer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventOrganizer.ViewAll"),
                claim: "Permissions.EventOrganizer.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/eventmanagement/eventorganizer/addeventorganizer",
                title: "MENUITEMS.EventOrganizer.LIST.NEW-EventOrganizer",
                moduleName: "EventOrganizer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.EventOrganizer.Create"),
                claim: "Permissions.EventOrganizer.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      // COMPETITIONMANAGEMENT
      {
        path: "",
        title: "MENUITEMS.COMPETITIONMANAGEMENT.TEXT",
        moduleName: "competitionmanagement",
        iconType: "material-icons-two-tone",
        icon: "football",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "match-ico.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.Stadium.ViewAll', 'Permissions.Matches.ViewAll', 'Permissions.Staff.ViewAll', 'Permissions.StaffCategory.ViewAll', 'Permissions.SponsorType.ViewAll']),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.STADIUM.TEXT",
            moduleName: "stadium",
            iconType: "material-icons-two-tone",
            icon: "medical_services",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "stadium-ico.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Stadium.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/competitionmanagement/stadium/allstadium",
                title: "MENUITEMS.STADIUM.LIST.All-STADIUM",
                moduleName: "stadium",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "stadium-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Stadium.ViewAll"),
                claim: "Permissions.Stadium.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/stadium/addstadium",
                title: "MENUITEMS.STADIUM.LIST.NEW-STADIUM",
                moduleName: "stadium",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-stadium-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Stadium.Create"),
                claim: "Permissions.Stadium.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Match.TEXT",
            moduleName: "match",
            iconType: "material-icons-two-tone",
            icon: "medical_information",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "match.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Matches.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/competitionmanagement/match/allmatch",
                title: "MENUITEMS.Match.LIST.All-Match",
                moduleName: "match",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "match.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Matches.ViewAll"),
                claim: "Permissions.Matches.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/match/addmatch",
                title: "MENUITEMS.Match.LIST.NEW-Match",
                moduleName: "match",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-match.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Matches.Create"),
                claim: "Permissions.Matches.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.STAFF.TEXT",
            moduleName: "staff",
            iconType: "material-icons-two-tone",
            icon: "support_agent",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "staff.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Staff.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/competitionmanagement/staff/allstaff",
                title: "MENUITEMS.STAFF.LIST.All-STAFF",
                moduleName: "staff",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "staff.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Staff.ViewAll"),
                claim: "Permissions.Staff.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/staff/addstaff",
                title: "MENUITEMS.STAFF.LIST.NEW-STAFF",
                moduleName: "staff",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-staff.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Staff.Create"),
                claim: "Permissions.Staff.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.STAFFCATEGORY.TEXT",
            moduleName: "staffcategory",
            iconType: "material-icons-two-tone",
            icon: "support_agent",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "staffcategory.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.StaffCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/competitionmanagement/staffcategory/allstaffcategory",
                title: "MENUITEMS.STAFFCATEGORY.LIST.All-STAFFCATEGORY",
                moduleName: "staffcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "staffcategory.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.StaffCategory.ViewAll"),
                claim: "Permissions.StaffCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/staffcategory/addstaffcategory",
                title: "MENUITEMS.STAFFCATEGORY.LIST.NEW-STAFFCATEGORY",
                moduleName: "staffcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-staffcategory.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.StaffCategory.Create"),
                claim: "Permissions.StaffCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.SPONSORTYPE.TEXT",
            moduleName: "sponsortype",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "sponsortype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.SponsorType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/competitionmanagement/sponsortype/allsponsortype",
                title: "MENUITEMS.SPONSORTYPE.LIST.All-SPONSORTYPE",
                moduleName: "sponsortype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "sponsortype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SponsorType.ViewAll"),
                claim: "Permissions.SponsorType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/sponsortype/addsponsortype",
                title: "MENUITEMS.SPONSORTYPE.LIST.NEW-SPONSORTYPE",
                moduleName: "sponsortype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-sponsortype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.SponsorType.Create"),
                claim: "Permissions.SponsorType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Player.TEXT",
            moduleName: "player",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "player.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Player.ViewAll"),
            claim: "Permissions.Player.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/player/allplayer",
                title: "MENUITEMS.Player.LIST.All-Player",
                moduleName: "player",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Player.ViewAll"),
                claim: "Permissions.Player.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/player/addplayer",
                title: "MENUITEMS.Player.LIST.NEW-Player",
                moduleName: "player",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Player.Create"),
                claim: "Permissions.Player.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PlayerTeamRequest.TEXT",
            moduleName: "playerteamrequest",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "player.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/playerteamrequest/allplayerteamrequest",
                title: "MENUITEMS.PlayerTeamRequest.LIST.All-PlayerTeamRequest",
                moduleName: "playerteamrequest",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/playerteamrequest/addplayerteamrequest",
                title: "MENUITEMS.PlayerTeamRequest.LIST.NEW-PlayerTeamRequest",
                moduleName: "playerteamrequest",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PlayerCategory.TEXT",
            moduleName: "playercategory",
            iconType: "material-icons-two-tone",
            icon: "",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "player.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PlayerCategory.ViewAll"),
            claim: "Permissions.PlayerCategory.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/playercategory/allplayercategory",
                title: "MENUITEMS.PlayerCategory.LIST.All-PlayerCategory",
                moduleName: "playercategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PlayerCategory.ViewAll"),
                claim: "Permissions.PlayerCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/playercategory/addplayercategory",
                title: "MENUITEMS.PlayerCategory.LIST.NEW-PlayerCategory",
                moduleName: "playercategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PlayerCategory.Create"),
                claim: "Permissions.PlayerCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Refree.TEXT",
            moduleName: "refree",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "refree.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Refree.ViewAll"),
            claim: "Permissions.Refree.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/refree/allrefree",
                title: "MENUITEMS.Refree.LIST.All-Refree",
                moduleName: "refree",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Refree.ViewAll"),
                claim: "Permissions.Refree.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/refree/addrefree",
                title: "MENUITEMS.Refree.LIST.NEW-Refree",
                moduleName: "refree",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Refree.Create"),
                claim: "Permissions.Refree.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.RefreeCategory.TEXT",
            moduleName: "refreecategory",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "refree.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.RefreeCategory.ViewAll"),
            claim: "Permissions.RefreeCategory.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/refreecategory/allrefreecategory",
                title: "MENUITEMS.RefreeCategory.LIST.All-RefreeCategory",
                moduleName: "refreecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RefreeCategory.ViewAll"),
                claim: "Permissions.RefreeCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/refreecategory/addrefreecategory",
                title: "MENUITEMS.RefreeCategory.LIST.NEW-RefreeCategory",
                moduleName: "refreecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RefreeCategory.Create"),
                claim: "Permissions.RefreeCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Coach.TEXT",
            moduleName: "coach",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "coach.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Coach.ViewAll"),
            claim: "Permissions.Coach.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/coach/allcoach",
                title: "MENUITEMS.Coach.LIST.All-Coach",
                moduleName: "coach",
                iconType: "",
                icon: "coach",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Coach.ViewAll"),
                claim: "Permissions.Coach.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/coach/addcoach",
                title: "MENUITEMS.Coach.LIST.NEW-Coach",
                moduleName: "coach",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Coach.Create"),
                claim: "Permissions.Coach.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.CoachCategory.TEXT",
            moduleName: "coachcategory",
            iconType: "material-icons-two-tone",
            icon: "coach",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "coach.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.CoachCategory.ViewAll"),
            claim: "Permissions.CoachCategory.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/coachcategory/allcoachcategory",
                title: "MENUITEMS.CoachCategory.LIST.All-CoachCategory",
                moduleName: "coachcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CoachCategory.ViewAll"),
                claim: "Permissions.CoachCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/coachcategory/addcoachcategory",
                title: "MENUITEMS.CoachCategory.LIST.NEW-CoachCategory",
                moduleName: "coachcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.CoachCategory.Create"),
                claim: "Permissions.CoachCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },

          {
            path: "",
            title: "MENUITEMS.AwardCategory.TEXT",
            moduleName: "awardcategory",
            iconType: "material-icons-two-tone",
            icon: "award",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "award.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/awardcategory/allawardcategory",
                title: "MENUITEMS.AwardCategory.LIST.All-AwardCategory",
                moduleName: "awardcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/awardcategory/addawardcategory",
                title: "MENUITEMS.AwardCategory.LIST.NEW-AwardCategory",
                moduleName: "awardcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Award.TEXT",
            moduleName: "award",
            iconType: "material-icons-two-tone",
            icon: "award",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "award.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/award/allaward",
                title: "MENUITEMS.Award.LIST.All-Award",
                moduleName: "award",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/award/addaward",
                title: "MENUITEMS.Award.LIST.NEW-Award",
                moduleName: "award",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.AwardType.TEXT",
            moduleName: "awardtype",
            iconType: "material-icons-two-tone",
            icon: "award",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "award.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/awardtype/allawardtype",
                title: "MENUITEMS.AwardType.LIST.All-AwardType",
                moduleName: "awardtype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/awardtype/addawardtype",
                title: "MENUITEMS.AwardType.LIST.NEW-AwardType",
                moduleName: "awardtype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },


          {
            path: "",
            title: "MENUITEMS.SponsorCategory.TEXT",
            moduleName: "sponsorcategory",
            iconType: "material-icons-two-tone",
            icon: "sponsor",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "sponsor.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/sponsorcategory/allsponsorcategory",
                title: "MENUITEMS.SponsorCategory.LIST.All-SponsorCategory",
                moduleName: "sponsorcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/sponsorcategory/addsponsorcategory",
                title: "MENUITEMS.SponsorCategory.LIST.NEW-SponsorCategory",
                moduleName: "sponsorcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Sponsor.TEXT",
            moduleName: "sponsor",
            iconType: "material-icons-two-tone",
            icon: "sponsor",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "sponsor.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/sponsor/allsponsor",
                title: "MENUITEMS.Sponsor.LIST.All-Sponsor",
                moduleName: "sponsor",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/sponsor/addsponsor",
                title: "MENUITEMS.Sponsor.LIST.NEW-Sponsor",
                moduleName: "sponsor",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ParamedicCategory.TEXT",
            moduleName: "paramediccategory",
            iconType: "material-icons-two-tone",
            icon: "paramedic",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "paramedic.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ParamedicCategory.ViewAll"),
            claim: "Permissions.ParamedicCategory.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/paramediccategory/allparamediccategory",
                title: "MENUITEMS.ParamedicCategory.LIST.All-ParamedicCategory",
                moduleName: "paramediccategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ParamedicCategory.ViewAll"),
                claim: "Permissions.ParamedicCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/paramediccategory/addparamediccategory",
                title: "MENUITEMS.ParamedicCategory.LIST.NEW-ParamedicCategory",
                moduleName: "paramediccategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ParamedicCategory.Create"),
                claim: "Permissions.ParamedicCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Paramedic.TEXT",
            moduleName: "paramedic",
            iconType: "material-icons-two-tone",
            icon: "paramedic",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "paramedic.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Paramedic.ViewAll"),
            claim: "Permissions.Paramedic.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/paramedic/allparamedic",
                title: "MENUITEMS.Paramedic.LIST.All-Paramedic",
                moduleName: "paramedic",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Paramedic.ViewAll"),
                claim: "Permissions.Paramedic.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/paramedic/addparamedic",
                title: "MENUITEMS.Paramedic.LIST.NEW-Paramedic",
                moduleName: "paramedic",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Paramedic.Create"),
                claim: "Permissions.Paramedic.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.AgeCategory.TEXT",
            moduleName: "agecategory",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "agecategory.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/agecategory/allagecategory",
                title: "MENUITEMS.AgeCategory.LIST.All-AgeCategory",
                moduleName: "agecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/agecategory/addagecategory",
                title: "MENUITEMS.AgeCategory.LIST.NEW-AgeCategory",
                moduleName: "agecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.StadiumCategory.TEXT",
            moduleName: "stadiumcategory",
            iconType: "material-icons-two-tone",
            icon: "stadium",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "stadium.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.StadiumCategory.ViewAll"),
            claim: "Permissions.StadiumCategory.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/stadiumcategory/allstadiumcategory",
                title: "MENUITEMS.StadiumCategory.LIST.All-StadiumCategory",
                moduleName: "stadiumcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.StadiumCategory.ViewAll"),
                claim: "Permissions.StadiumCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/stadiumcategory/addstadiumcategory",
                title: "MENUITEMS.StadiumCategory.LIST.NEW-StadiumCategory",
                moduleName: "stadiumcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.StadiumCategory.Create"),
                claim: "Permissions.StadiumCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TeamCategory.TEXT",
            moduleName: "teamcategory",
            iconType: "material-icons-two-tone",
            icon: "team",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "team.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TeamCategory.ViewAll"),
            claim: "Permissions.TeamCategory.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/teamcategory/allteamcategory",
                title: "MENUITEMS.TeamCategory.LIST.All-TeamCategory",
                moduleName: "teamcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TeamCategory.ViewAll"),
                claim: "Permissions.TeamCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/teamcategory/addteamcategory",
                title: "MENUITEMS.TeamCategory.LIST.NEW-TeamCategory",
                moduleName: "teamcategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TeamCategory.Create"),
                claim: "Permissions.TeamCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Team.TEXT",
            moduleName: "team",
            iconType: "material-icons-two-tone",
            icon: "team",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "team.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Team.ViewAll"),
            claim: "Permissions.Team.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/team/allteam",
                title: "MENUITEMS.Team.LIST.All-Team",
                moduleName: "team",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Team.ViewAll"),
                claim: "Permissions.Team.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/team/addteam",
                title: "MENUITEMS.Team.LIST.NEW-Team",
                moduleName: "team",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Team.Create"),
                claim: "Permissions.Team.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TeamPlayer.TEXT",
            moduleName: "teamplayer",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/teamplayer/allteamplayer",
                title: "MENUITEMS.TeamPlayer.LIST.All-TeamPlayer",
                moduleName: "TeamPlayer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/teamplayer/addteamplayer",
                title: "MENUITEMS.TeamPlayer.LIST.NEW-TeamPlayer",
                moduleName: "TeamPlayer",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TrainingCamp.TEXT",
            moduleName: "trainingcamp",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "training.svg",
            role: ["All"],
            displayPermission: true,

            submenu: [

              {
                path: "/admin/competitionmanagement/trainingcamp/alltrainingcamp",
                title: "MENUITEMS.TrainingCamp.LIST.All-TrainingCamp",
                moduleName: "TrainingCamp",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/trainingcamp/addtrainingcamp",
                title: "MENUITEMS.TrainingCamp.LIST.NEW-TrainingCamp",
                moduleName: "TrainingCamp",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: true,

                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Season.TEXT",
            moduleName: "season",
            iconType: "material-icons-two-tone",
            icon: "season",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "season.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Season.ViewAll"),
            claim: "Permissions.Season.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/season/allseason",
                title: "MENUITEMS.Season.LIST.All-Season",
                moduleName: "season",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Season.ViewAll"),
                claim: "Permissions.Season.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/season/addseason",
                title: "MENUITEMS.Season.LIST.NEW-Season",
                moduleName: "season",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Season.Create"),
                claim: "Permissions.Season.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Game.TEXT",
            moduleName: "game",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "game9.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Game.ViewAll"),
            claim: "Permissions.Game.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/game/allgame",
                title: "MENUITEMS.Game.LIST.All-Game",
                moduleName: "game",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Game.ViewAll"),
                claim: "Permissions.Game.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/game/addgame",
                title: "MENUITEMS.Game.LIST.NEW-Game",
                moduleName: "game",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Game.Create"),
                claim: "Permissions.Game.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.Championships.TEXT",
            moduleName: "championships",
            iconType: "material-icons-two-tone",
            icon: "championships",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "championships.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Championship.ViewAll"),
            claim: "Permissions.Championship.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/championships/allchampionships",
                title: "MENUITEMS.Championships.LIST.All-Championships",
                moduleName: "championships",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Championship.ViewAll"),
                claim: "Permissions.Championship.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/championships/addchampionships",
                title: "MENUITEMS.Championships.LIST.NEW-Championships",
                moduleName: "championships",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Championship.Create"),
                claim: "Permissions.Championship.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ChampionshipType.TEXT",
            moduleName: "championshiptype",
            iconType: "material-icons-two-tone",
            icon: "championshiptype",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "championshiptype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ChampionshipType.ViewAll"),
            claim: "Permissions.ChampionshipType.ViewAll",
            favorite: false,
            submenu: [

              {
                path: "/admin/competitionmanagement/championshiptype/allchampionshiptype",
                title: "MENUITEMS.ChampionshipType.LIST.All-ChampionshipType",
                moduleName: "championshiptype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ChampionshipType.ViewAll"),
                claim: "Permissions.ChampionshipType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/competitionmanagement/championshiptype/addchampionshiptype",
                title: "MENUITEMS.ChampionshipType.LIST.NEW-ChampionshipType",
                moduleName: "championshiptype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ChampionshipType.Create"),
                claim: "Permissions.ChampionshipType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ]

      },
      // TailorSection
      {
        path: "",
        title: "MENUITEMS.TailorSection.TEXT",
        moduleName: "tailorsection",
        iconType: "material-icons-two-tone",
        icon: "group_work",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "Tailor.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorized("Permissions.Tailors.ViewAll"),
        claim: "Permissions.Tailors.ViewAll",
        favorite: false,
        submenu: [
          {
            path: "/admin/tailorsection/alltailorsection",
            title: "MENUITEMS.TailorSection.LIST.All-TailorSection",
            moduleName: "tailorsection",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "",
            role: [""],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tailors.ViewAll"),
            claim: "Permissions.Tailors.ViewAll",
            favorite: false,
            submenu: [],
          },
          {
            path: "/admin/tailorsection/addtailorsection",
            title: "MENUITEMS.TailorSection.LIST.NEW-TailorSection",
            moduleName: "tailorsection",
            iconType: "",
            icon: "",
            class: "ml-menu",
            groupTitle: false,
            iconPath: "",
            role: [""],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Tailors.Create"),
            claim: "Permissions.Tailors.Create",
            favorite: false,
            submenu: [],
          }
        ],
      },
      // PARKING
      {
        path: "",
        title: "MENUITEMS.ParkingManagement.TEXT",
        moduleName: "parking",
        iconType: "material-icons-two-tone",
        icon: "home",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "parking-setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.ParkingArea.ViewAll', 'Permissions.ParkingPriceType.ViewAll']),

        submenu: [
          //BRAND
          {
            path: "",
            title: "MENUITEMS.BRAND.TEXT",
            moduleName: "brand",
            iconType: "material-icons-two-tone",
            icon: "group_work",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "BRAND.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Brand.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/parking/brand/allbrand",
                title: "MENUITEMS.BRAND.LIST.All-BRAND",
                moduleName: "brand",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "BRAND.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Brand.ViewAll"),
                claim: "Permissions.Brand.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/parking/brand/addbrand",
                title: "MENUITEMS.BRAND.LIST.NEW-BRAND",
                moduleName: "brand",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "BRAND.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Brand.Create"),
                claim: "Permissions.Brand.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PARKINGAREA.TEXT",
            moduleName: "parkingArea",
            iconType: "material-icons-two-tone",
            icon: "local_parking",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "parking.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ParkingArea.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/parking/parkingArea/allparkingArea",
                title: "MENUITEMS.PARKINGAREA.LIST.All-PARKINGAREA",
                moduleName: "parkingArea",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "parking.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ParkingArea.ViewAll"),
                claim: "Permissions.ParkingArea.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/parking/parkingArea/addparkingArea",
                title: "MENUITEMS.PARKINGAREA.LIST.NEW-PARKINGAREA",
                moduleName: "parkingArea",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-parking.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ParkingArea.Create"),
                claim: "Permissions.ParkingArea.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.PARKINGPRICETYPE.TEXT",
            moduleName: "parkingpricetype",
            iconType: "material-icons-two-tone",
            icon: "bug_report",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "parkingpricetype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.ParkingPriceType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/parking/parkingpricetype/allparkingpricetype",
                title: "MENUITEMS.PARKINGPRICETYPE.LIST.All-PARKINGPRICETYPE",
                moduleName: "parkingpricetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "parkingpricetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ParkingPriceType.ViewAll"),
                claim: "Permissions.ParkingPriceType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/parking/parkingpricetype/addparkingpricetype",
                title: "MENUITEMS.PARKINGPRICETYPE.LIST.NEW-PARKINGPRICETYPE",
                moduleName: "parkingpricetype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-parkingpricetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.ParkingPriceType.Create"),
                claim: "Permissions.ParkingPriceType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      //BOOKINGSYSTEM
      {
        path: "",
        title: "MENUITEMS.ROUTE.TEXT",
        moduleName: "routes",
        iconType: "material-icons-two-tone",
        icon: "noun-booking",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "noun-route.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.PickupPoint.ViewAll', 'Permissions.RouteType.ViewAll', 'Permissions.Route.ViewAll']),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.PICKUPPOINT.TEXT",
            moduleName: "pickuppoint",
            iconType: "material-icons-two-tone",
            icon: "noun-route",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "noun-route.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.PickupPoint.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/routes/pickuppoint/allpickuppoint",
                title: "MENUITEMS.PICKUPPOINT.LIST.All-PICKUPPOINT",
                moduleName: "pickuppoint",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "pickuppoint.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PickupPoint.ViewAll"),
                claim: "Permissions.PickupPoint.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/routes/pickuppoint/addpickuppoint",
                title: "MENUITEMS.PICKUPPOINT.LIST.NEW-PICKUPPOINT",
                moduleName: "pickuppoint",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-pickuppoint.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.PickupPoint.Create"),
                claim: "Permissions.PickupPoint.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ROUTETYPES.TEXT",
            moduleName: "route-types",
            iconType: "material-icons-two-tone",
            icon: "route-types",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "route-types.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.RouteType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/routes/routetypes/allroutetypes",
                title: "MENUITEMS.ROUTETYPES.LIST.All-ROUTETYPES",
                moduleName: "routetypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "routetypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RouteType.ViewAll"),
                claim: "Permissions.RouteType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/routes/routetypes/addroutetypes",
                title: "MENUITEMS.ROUTETYPES.LIST.NEW-ROUTETYPES",
                moduleName: "routetypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-routetypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.RouteType.Create"),
                claim: "Permissions.RouteType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.ROUTES.TEXT",
            moduleName: "routes",
            iconType: "material-icons-two-tone",
            icon: "noun-route",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "noun-route.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Route.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/routes/routes/allroutes",
                title: "MENUITEMS.ROUTES.LIST.All-ROUTES",
                moduleName: "routes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "routes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Route.ViewAll"),
                claim: "Permissions.Route.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/routes/routes/addroutes",
                title: "MENUITEMS.ROUTES.LIST.NEW-ROUTES",
                moduleName: "routes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-routes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Route.Create"),
                claim: "Permissions.Route.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
        ],
      },
      {
        path: "",
        title: "MENUITEMS.STATION.TEXT",
        moduleName: "station",
        iconType: "material-icons-two-tone",
        icon: "dashboard",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "operations.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.StationType.ViewAll', 'Permissions.TerminalType.ViewAll', 'Permissions.GateType.ViewAll', 'Permissions.Station.ViewAll']),

        submenu: [

          {
            path: "",
            title: "MENUITEMS.STATIONTYPES.TEXT",
            moduleName: "stationtypes",
            iconType: "material-icons-two-tone",
            icon: "stationtypes",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "stationtypes.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.StationType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/station/stationtypes/allstationtypes",
                title: "MENUITEMS.STATIONTYPES.LIST.All-STATIONTYPES",
                moduleName: "stationtypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "stationtypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.StationType.ViewAll"),
                claim: "Permissions.StationType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/station/stationtypes/addstationtypes",
                title: "MENUITEMS.STATIONTYPES.LIST.NEW-STATIONTYPES",
                moduleName: "stationtypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-stationtypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.StationType.Create"),
                claim: "Permissions.StationType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },

          {
            path: "",
            title: "MENUITEMS.TERMINALTYPES.TEXT",
            moduleName: "terminal-types",
            iconType: "material-icons-two-tone",
            icon: "terminaltypes",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "terminal-types.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TerminalType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/station/terminaltypes/allterminaltypes",
                title: "MENUITEMS.TERMINALTYPES.LIST.All-TERMINALTYPES",
                moduleName: "terminaltypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "terminaltypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TerminalType.ViewAll"),
                claim: "Permissions.TerminalType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/station/terminaltypes/addterminaltypes",
                title: "MENUITEMS.TERMINALTYPES.LIST.NEW-TERMINALTYPES",
                moduleName: "terminaltypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-terminaltypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TerminalType.Create"),
                claim: "Permissions.TerminalType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },

          {
            path: "",
            title: "MENUITEMS.GATETYPES.TEXT",
            moduleName: "gatetypes",
            iconType: "material-icons-two-tone",
            icon: "gatetype",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "gatetype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.GateType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/station/gatetypes/allgatetypes",
                title: "MENUITEMS.GATETYPES.LIST.All-GATETYPES",
                moduleName: "gatetypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "gatetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.GateType.ViewAll"),
                claim: "Permissions.GateType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/station/gatetypes/addgatetypes",
                title: "MENUITEMS.GATETYPES.LIST.NEW-GATETYPES",
                moduleName: "gatetypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-gatetype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.GateType.Create"),
                claim: "Permissions.GateType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.STATIONS.TEXT",
            moduleName: "station",
            iconType: "material-icons-two-tone",
            icon: "noun-station",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "noun-station.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Station.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/station/stations/allstations",
                title: "MENUITEMS.STATIONS.LIST.All-STATIONS",
                moduleName: "stations",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "stations.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Station.ViewAll"),
                claim: "Permissions.Station.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/station/stations/addstations",
                title: "MENUITEMS.STATIONS.LIST.NEW-STATIONS",
                moduleName: "stations",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-stations.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Station.Create"),
                claim: "Permissions.Station.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },

        ],
      },
      {
        path: "",
        title: "MENUITEMS.VEHICLE.TEXT",
        moduleName: "vehicles",
        iconType: "material-icons-two-tone",
        icon: "dashboard",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "setting.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.VehicleFuelType.ViewAll', 'Permissions.VehicleCategory.ViewAll', 'Permissions.VehicleEngineType.ViewAll', 'Permissions.VehicleMaker.ViewAll', "Permissions.VehicleStyle.ViewAll", "Permissions.VehicleType.ViewAll", "Permissions.TransportationType.ViewAll", "Permissions.Vehicle.ViewAll"]),

        submenu: [
          {
            path: "",
            title: "MENUITEMS.FUELTYPE.TEXT",
            moduleName: "fueltype",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "fueltype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleFuelType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/fueltype/allfueltype",
                title: "MENUITEMS.FUELTYPE.LIST.All-FUELTYPE",
                moduleName: "fueltype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "fueltype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleFuelType.ViewAll"),
                claim: "Permissions.VehicleFuelType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/fueltype/addfueltype",
                title: "MENUITEMS.FUELTYPE.LIST.NEW-FUELTYPE",
                moduleName: "fueltype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-fueltype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleFuelType.Create"),
                claim: "Permissions.VehicleFuelType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.VEHICLECATEGORIES.TEXT",
            moduleName: "vehiclecategories",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "vehiclecategories.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/vehiclecategories/allvehiclecategories",
                title: "MENUITEMS.VEHICLECATEGORIES.LIST.All-VEHICLECATEGORIES",
                moduleName: "vehiclecategories",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "vehiclecategories.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleCategory.ViewAll"),
                claim: "Permissions.VehicleCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/vehiclecategories/addvehiclecategories",
                title: "MENUITEMS.VEHICLECATEGORIES.LIST.NEW-VEHICLECATEGORIES",
                moduleName: "vehiclecategories",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-vehiclecategories.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleCategory.Create"),
                claim: "Permissions.VehicleCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.VEHICLEENGINETYPES.TEXT",
            moduleName: "vehicleenginetypes",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "vehicletypes.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleEngineType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/vehicleenginetypes/allvehicleenginetypes",
                title: "MENUITEMS.VEHICLEENGINETYPES.LIST.All-VEHICLEENGINETYPES",
                moduleName: "vehicleenginetypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "vehicletypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleEngineType.ViewAll"),
                claim: "Permissions.VehicleEngineType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/vehicleenginetypes/addvehicleenginetypes",
                title: "MENUITEMS.VEHICLEENGINETYPES.LIST.NEW-VEHICLEENGINETYPES",
                moduleName: "vehicleenginetypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-vehicletypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleEngineType.Create"),
                claim: "Permissions.VehicleEngineType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.VEHICLEMAKER.TEXT",
            moduleName: "vehiclemaker",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "vehiclemaker.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleMaker.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/vehiclemaker/allvehiclemaker",
                title: "MENUITEMS.VEHICLEMAKER.LIST.All-VEHICLEMAKER",
                moduleName: "vehiclemaker",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "vehiclemaker.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleMaker.ViewAll"),
                claim: "Permissions.VehicleMaker.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/vehiclemaker/addvehiclemaker",
                title: "MENUITEMS.VEHICLEMAKER.LIST.NEW-VEHICLEMAKER",
                moduleName: "vehiclemaker",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-vehiclemaker.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleMaker.Create"),
                claim: "Permissions.VehicleMaker.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.VEHICLESTYLES.TEXT",
            moduleName: "vehiclestyles",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "vehiclestyles.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleStyle.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/vehiclestyles/allvehiclestyles",
                title: "MENUITEMS.VEHICLESTYLES.LIST.All-VEHICLESTYLES",
                moduleName: "vehiclestyles",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "vehiclestyles.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleStyle.ViewAll"),
                claim: "Permissions.VehicleStyle.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/vehiclestyles/addvehiclestyles",
                title: "MENUITEMS.VEHICLESTYLES.LIST.NEW-VEHICLESTYLES",
                moduleName: "vehiclestyles",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-vehiclestyles.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleStyle.Create"),
                claim: "Permissions.VehicleStyle.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.VEHICLETYPES.TEXT",
            moduleName: "vehicletypes",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "vehicletypes.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/vehicletypes/allvehicletypes",
                title: "MENUITEMS.VEHICLETYPES.LIST.All-VEHICLETYPES",
                moduleName: "vehicletypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "vehicletypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleType.ViewAll"),
                claim: "Permissions.VehicleType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/vehicletypes/addvehicletypes",
                title: "MENUITEMS.VEHICLETYPES.LIST.NEW-VEHICLETYPES",
                moduleName: "vehicletypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-vehicletypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleType.Create"),
                claim: "Permissions.VehicleType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TRANSPORTATIONTYPES.TEXT",
            moduleName: "transportationtypes",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "transportationtypes.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TransportationType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/transportationtypes/alltransportationtypes",
                title: "MENUITEMS.TRANSPORTATIONTYPES.LIST.All-TRANSPORTATIONTYPES",
                moduleName: "transportationtypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "transportationtypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TransportationType.ViewAll"),
                claim: "Permissions.TransportationType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/transportationtypes/addtransportationtypes",
                title: "MENUITEMS.TRANSPORTATIONTYPES.LIST.NEW-TRANSPORTATIONTYPES",
                moduleName: "transportationtypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-transportationtypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TransportationType.Create"),
                claim: "Permissions.TransportationType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.VEHICLES.TEXT",
            moduleName: "vehicles",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "vehicles.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Vehicle.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/vehicles/vehicles/allvehicles",
                title: "MENUITEMS.VEHICLES.LIST.All-VEHICLES",
                moduleName: "vehicles",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "vehicles.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Vehicle.ViewAll"),
                claim: "Permissions.Vehicle.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/vehicles/vehicles/addvehicles",
                title: "MENUITEMS.VEHICLES.LIST.NEW-VEHICLES",
                moduleName: "vehicles",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-vehicles.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Vehicle.Create"),
                claim: "Permissions.Vehicle.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },

        ],
      },
      {
        path: "",
        title: "MENUITEMS.TRIP.TEXT",
        moduleName: "trips",
        iconType: "material-icons-two-tone",
        icon: "dashboard",
        class: "menu-toggle",
        groupTitle: false,
        iconPath: "trips.svg",
        role: ["All"],
        displayPermission: this.permissionService.IsAuthorizedGroup(['Permissions.BookingType.ViewAll', 'Permissions.BookingService.ViewAll', 'Permissions.TripType.View', 'Permissions.TripCategory.ViewAll', "Permissions.Trip.ViewAll", "Permissions.TripBooking.ViewAll", "Permissions.VehicleDriver.ViewAll", "Permissions.TransportationTicketTypes.ViewAll", "Permissions.BookingAgeCategory.ViewAll"]),

        submenu: [

          {
            path: "",
            title: "MENUITEMS.BOOKINGTYPE.TEXT",
            moduleName: "bookingtype",
            iconType: "material-icons-two-tone",
            icon: "appointments-svgrepo-com",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "bookingtype.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.BookingType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/bookingtype/allbookingtype",
                title: "MENUITEMS.BOOKINGTYPE.LIST.All-BOOKINGTYPE",
                moduleName: "bookingtype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "bookingtype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BookingType.ViewAll"),
                claim: "Permissions.BookingType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/bookingtype/addbookingtype",
                title: "MENUITEMS.BOOKINGTYPE.LIST.NEW-BOOKINGTYPE",
                moduleName: "bookingtype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-bookingtype.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BookingType.Create"),
                claim: "Permissions.BookingType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.BOOKINGSERVICE.TEXT",
            moduleName: "bookingservice",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Service-ico.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.BookingService.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/bookingservice/allbookingservice",
                title: "MENUITEMS.BOOKINGSERVICE.LIST.All-BOOKINGSERVICE",
                moduleName: "bookingservice",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "Service-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BookingService.ViewAll"),
                claim: "Permissions.BookingService.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/bookingservice/addbookingservice",
                title: "MENUITEMS.BOOKINGSERVICE.LIST.NEW-BOOKINGSERVICE",
                moduleName: "bookingservice",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-Service-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BookingService.Create"),
                claim: "Permissions.BookingService.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TRIPTYPES.TEXT",
            moduleName: "trip-types",
            iconType: "material-icons-two-tone",
            icon: "trip-types",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "trip-types.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TripType.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/triptypes/alltriptypes",
                title: "MENUITEMS.TRIPTYPES.LIST.All-TRIPTYPES",
                moduleName: "triptypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "triptypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TripType.ViewAll"),
                claim: "Permissions.TripType.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/triptypes/addtriptypes",
                title: "MENUITEMS.TRIPTYPES.LIST.NEW-TRIPTYPES",
                moduleName: "triptypes",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-triptypes.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TripType.Create"),
                claim: "Permissions.TripType.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TRIPCATEGORIES.TEXT",
            moduleName: "trip-categories",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "trip-categories.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TripCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/tripcategories/alltripcategories",
                title: "MENUITEMS.TRIPCATEGORIES.LIST.All-TRIPCATEGORIES",
                moduleName: "tripcategories",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "trip-categories.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TripCategory.ViewAll"),
                claim: "Permissions.TripCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/tripcategories/addtripcategories",
                title: "MENUITEMS.TRIPCATEGORIES.LIST.NEW-TRIPCATEGORIES",
                moduleName: "tripcategories",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-trip-categories.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TripCategory.Create"),
                claim: "Permissions.TripCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.BOOKINGTRIP.TEXT",
            moduleName: "bookingtrip",
            iconType: "material-icons-two-tone",
            icon: "trip-types",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "booking.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TripBooking.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/bookingtrip/allbookingtrip",
                title: "MENUITEMS.BOOKINGTRIP.LIST.All-BOOKINGTRIP",
                moduleName: "bookingtrip",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "booking.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TripBooking.ViewAll"),
                claim: "Permissions.TripBooking.ViewAll",
                favorite: false,
                submenu: [],
              },
              // {
              //   path: "/admin/trips/bookingtrip/addbookingtrip",
              //   title: "MENUITEMS.BOOKINGTRIP.LIST.NEW-BOOKINGTRIP",
              //   moduleName: "bookingtrip",
              //   iconType: "",
              //   icon: "",
              //   class: "ml-sub-sub-menu",
              //   groupTitle: false,
              //   iconPath: "add-triptypes.svg",
              //   role: [""],
              //   displayPermission: this.permissionService.IsAuthorized("Permissions.TripBooking.Create"),
              //claim: "Permissions.TripBooking.Create",
              // favorite:false,
              //   submenu: [],
              // }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TRIPS.TEXT",
            moduleName: "trips",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "trips.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.Trip.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/trips/alltrips",
                title: "MENUITEMS.TRIPS.LIST.All-TRIPS",
                moduleName: "trips",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "trips.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Trip.ViewAll"),
                claim: "Permissions.Trip.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/trips/addtrips",
                title: "MENUITEMS.TRIPS.LIST.NEW-TRIPS",
                moduleName: "trips",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-trips.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.Trip.Create"),
                claim: "Permissions.Trip.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.BOOKING.TEXT",
            moduleName: "booking",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "booking.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TripBooking.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/booking/allbooking",
                title: "MENUITEMS.BOOKING.LIST.All-BOOKING",
                moduleName: "booking",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "booking.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TripBooking.ViewAll"),
                claim: "Permissions.TripBooking.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/booking/addbooking",
                title: "MENUITEMS.BOOKING.LIST.NEW-BOOKING",
                moduleName: "booking",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-booking.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TripBooking.Create"),
                claim: "Permissions.TripBooking.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.DRIVERS.TEXT",
            moduleName: "drivers",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "drivers.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleDriver.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/drivers/alldrivers",
                title: "MENUITEMS.DRIVERS.LIST.All-DRIVERS",
                moduleName: "drivers",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "drivers.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleDriver.ViewAll"),
                claim: "Permissions.VehicleDriver.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/drivers/adddrivers",
                title: "MENUITEMS.DRIVERS.LIST.NEW-DRIVERS",
                moduleName: "drivers",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-drivers.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.VehicleDriver.Create"),
                claim: "Permissions.VehicleDriver.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.TRANSPORTATIONTICKETTYPE.TEXT",
            moduleName: "transportationtickettype",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "Ticket-ico.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.TransportationTicketTypes.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/transportationtickettype/alltransportationtickettype",
                title: "MENUITEMS.TRANSPORTATIONTICKETTYPE.LIST.All-TRANSPORTATIONTICKETTYPE",
                moduleName: "transportationtickettype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "Ticket-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TransportationTicketTypes.ViewAll"),
                claim: "Permissions.TransportationTicketTypes.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/transportationtickettype/addtransportationtickettype",
                title: "MENUITEMS.TRANSPORTATIONTICKETTYPE.LIST.NEW-TRANSPORTATIONTICKETTYPE",
                moduleName: "transportationtickettype",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-Ticket-ico.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.TransportationTicketTypes.Create"),
                claim: "Permissions.TransportationTicketTypes.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "MENUITEMS.BookingAgeCategory.TEXT",
            moduleName: "bookingagecategory",
            iconType: "material-icons-two-tone",
            icon: "sell",
            class: "ml-sub-menu",
            groupTitle: false,
            iconPath: "agecategory.svg",
            role: ["All"],
            displayPermission: this.permissionService.IsAuthorized("Permissions.BookingAgeCategory.ViewAll"),
            favorite: false,
            submenu: [
              {
                path: "/admin/trips/bookingagecategory/allbookingagecategory",
                title: "MENUITEMS.BookingAgeCategory.LIST.All-BookingAgeCategory",
                moduleName: "bookingagecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "agecategory.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BookingAgeCategory.ViewAll"),
                claim: "Permissions.BookingAgeCategory.ViewAll",
                favorite: false,
                submenu: [],
              },
              {
                path: "/admin/trips/bookingagecategory/addbookingagecategory",
                title: "MENUITEMS.BookingAgeCategory.LIST.NEW-BookingAgeCategory",
                moduleName: "bookingagecategory",
                iconType: "",
                icon: "",
                class: "ml-sub-sub-menu",
                groupTitle: false,
                iconPath: "add-agecategory.svg",
                role: [""],
                displayPermission: this.permissionService.IsAuthorized("Permissions.BookingAgeCategory.Create"),
                claim: "Permissions.BookingAgeCategory.Create",
                favorite: false,
                submenu: [],
              }
            ],
          },

        ],
      },
    ];
  }
}