<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img src="assets/images/min_logo.png" alt="" />
        <span class="logo-name"><img src="assets/images/logo.svg" alt="" /></span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon flex" style="width:40%">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon fontSet="material-icons-outlined">menu</mat-icon>
          </button>
        </li>
        <li class="headerSearch" style="width:53%">
          <!-- <p-autoComplete #autoComplete [showEmptyMessage]="true" [suggestions]="filtered" class="headerSearch"
            [group]="true" (completeMethod)="filterSide($event)" [(ngModel)]="selectedItme" (onSelect)="selected()"
            (onHide)="onHideFilter()" [style]="{'height': '39px'}" emptyMessage="{{'COMMON.NoDataFound' | translate}}"
            placeholder="{{'COMMON.Search' | translate}}">
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span>{{ group.key }}</span>
              </div>
            </ng-template>
            <ng-template let-side pTemplate="item">
              <a [routerLink]="side.url" style="color:black;">
                <span class="filter-item" [innerHTML]="highlightMatch(side.name)"></span>
              </a>
            </ng-template>
          </p-autoComplete> -->

          <div (click)="searchvisible=true" class="mat-ripple custom-search relative">
            <mat-icon class="custom-search__icon">search</mat-icon>
            <div class="flex-auto custom-search__placeholder"> {{'COMMON.Search' | translate}} </div>
            <div class="flex-none custom-search__key">Ctrl Space</div>
          </div>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="mobile-search">
          <button mat-icon-button (click)="searchvisible=true" class=" nav-notification-icons">
            <mat-icon fontSet="material-icons-outlined">search</mat-icon>
          </button>
        </li>
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon fontSet="material-icons-outlined">fullscreen</mat-icon>
          </button>
        </li>
        <!-- #END# Full Screen Button -->

        <li class="nav-item" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li>
        <!-- #START# Notifications-->
        <li class="nav-item nav-add-items" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons notification-icon">
            <mat-icon fontSet="material-icons-outlined">add</mat-icon>
          </button>
          <!-- [perfectScrollbar] -->
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <p-scrollPanel class="noti-list"
              style="position: relative; max-width: max-content;max-height: 400px;height: 400px;">
              <div class="menu">
                <!-- <p-accordion class="row">
                  <p-accordionTab [header]="item.title|translate" *ngFor="let item of sidebar" class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <ul>
                      <li *ngFor="let it of item.items">
                        <a class="dropdown-item" [routerLink]="it.value">
                          <span>{{it.text|translate}}</span>
                        </a>
                      </li>
                    </ul>
                  </p-accordionTab>
                </p-accordion> -->
                <mat-accordion class="row">
                  <mat-expansion-panel *ngFor="let item of sidebar" class="col-xl-4 col-lg-4 col-md-6 col-sm-12 p-0">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span class="flex-vertical-center">{{item.title|translate}}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                      <li *ngFor="let it of item.items">
                        <a class="dropdown-item" [routerLink]="it.value">
                          <span>{{it.text|translate}}</span>
                        </a>
                      </li>
                    </ul>
                  </mat-expansion-panel>
                </mat-accordion>
                <!-- <div *ngFor="let item of sidebar" class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <span class="menu-title">{{item.title|translate}}</span>
                  <ul>
                    <li *ngFor="let it of item.items">
                      <a class="justify-content-between" [routerLink]="it.value">
                        <span class="menu-info">
                          <span>{{it.text|translate}}</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </p-scrollPanel>
          </div>
        </li>

        <li class="nav-item" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons notification-icon">
            <mat-icon fontSet="material-icons-outlined">notifications_none</mat-icon>
            <span class="label-count" [ngClass]="unreadednotification>0?'unreaded':''"
              id="label-count">{{unreadednotification>0?unreadednotification:''}}</span>
          </button>
          <!-- [perfectScrollbar] -->
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 596px;">
              <p class="tilte">{{'Notification.Notification'|translate}}</p>
              <div class="notification-filter">
                <span [ngClass]="notificationFilter==0?'active':''"
                  (click)="notificationFilterChanged(0)">{{'COMMON.All'|translate}}</span>
                <span [ngClass]="notificationFilter==1?'active':''"
                  (click)="notificationFilterChanged(1)">{{'Notification.Readed'|translate}}</span>
                <span [ngClass]="notificationFilter==2?'active':''"
                  (click)="notificationFilterChanged(2)">{{'Notification.Ignored'|translate}}</span>
              </div>
              <ul class="menu">
                <li>
                  <p class="tilte" *ngIf="notifications.length==0">{{'COMMON.NoDataFound'|translate}}</p>
                  <a *ngFor="let notification of notifications" (click)="gotoNotificationDetails(notification.id)"
                    [ngClass]="notification.read==false?'unreaded-notification':''" class="justify-content-between row"
                    onClick="return false;">
                    <!-- <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span> -->
                    <span class="menu-info col-9">
                      <span class="menu-title">{{notification.title}}</span>
                      <span class="menu-desc">{{notification.message| slice:0:40}}... </span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.createdOn| dateAgo}}
                      </span>
                    </span>
                    <span class="col-3">
                      <button class="mat-icon-button mat-button-base"><mat-icon fontSet="material-icons-outlined"
                          class="mat-icon-in-button">notifications_off
                        </mat-icon></button>
                      <!-- <div class="dropdown">
                        <button class="btn btn-light" type="button" id="dropdownMenuButton1" style="background: none;"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="pi pi-ellipsis-v" style="font-size: 1rem;vertical-align: middle;"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="width:auto">
                          <li>
                            <a class="dropdown-item" style="padding:3px 5px;" (click)="importProduct()">
                              <i class="fas fa-check-double actionSetting"></i> {{ 'Notification.MarkAsReaded' |
                              translate }}
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" style="padding:3px 5px;" (click)="doThing('deletelist')">
                              <mat-icon fontSet="material-icons-outlined"  class="actionSetting">notifications_off</mat-icon>{{ 'Notification.Ignore' | translate }}
                            </a>
                          </li>
                        </ul>
                      </div> -->
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a routerLink="currentuser/mynotifications" (click)="close()"
              class="text-center d-block border-top all-notification">
              {{'Notification.MyNotifications'|translate}}
            </a>
          </div>
        </li>
        <!-- #END# Notifications-->
        <!-- [ngClass]="{'active': langStoreValue === item.lang}" -->

        <!-- branch -->
        <!-- <li class="nav-item" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            {{Selectedbranch.text}}
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of BranchDropDown"
              (click)="setbranch(item.value, item.text)">
              <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li> -->
        <!-- end branch -->
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <!-- <img *ngIf="userImg" [src]="API_File_URL+'/'+userImg" class="rounded-circle" width="32" height="32"
              alt="User">
            <img *ngIf="!userImg" src="../assets/images/user/user1.jpg" class="rounded-circle" width="32"
              height="32" alt="User"> -->
            <img src="{{userImg}}" class="rounded-circle" width="32" height="32" alt="User"
              (error)="onImgError($event)">
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <!-- [perfectScrollbar] -->
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;">
              <div class="menu-profile-header flex">
                <img src="{{userImg}}" alt="profile-image">
                <div class="title"><a routerLink="currentuser/profile">{{userName}}</a>
                  <span class="designation"> {{userType | slice:0:22}} <span *ngIf="userType.length>22">...</span>
                  </span>
                </div>
              </div>
              <ul class="user_dw_menu">
                <li>
                  <a routerLink="currentuser/profile" onClick="return false;">
                    <i class="far fa-user"></i>{{ 'Profile.Profile' | translate }}
                  </a>
                </li>
                <li>
                  <a routerLink="admin/management/appsettings/appsettings" onClick="return false;">
                    <i class="fas fa-cog"></i>{{ 'MENUITEMS.APPSETTINGS.TEXT' | translate }}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="logout()">
                    <i class="fas fa-power-off"></i>{{ 'COMMON.Logout' |
                    translate }}
                  </a>
                </li>
              </ul>
              <!-- <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a routerLink="currentuser/profile" onClick="return false;">
                        <mat-icon fontSet="material-icons-outlined"  aria-hidden="false" class="msr-2">person</mat-icon>{{ 'Profile.Profile' | translate }}
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon fontSet="material-icons-outlined"  aria-hidden="false" class="msr-2">power_settings_new</mat-icon>{{ 'COMMON.Logout' |
                        translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul> -->
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
        <!-- <li class="float-end">
          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar">
            <mat-icon fontSet="material-icons-outlined"  id="settingBtn">settings</mat-icon>
          </button>
        </li> -->
      </ul>
    </div>
  </div>
</nav>

<p-dialog header="Header" class="custom_search_dialog" [(visible)]="searchvisible" [modal]="true"
  [style]="{ width: '50rem',height: '70vh' }">
  <ng-template pTemplate="header">
    <mat-icon fontSet="material-icons-outlined" matPrefix>search</mat-icon>
    <input type="text" class="search_input" (input)="filterSidebar($event)" pInputText [(ngModel)]="filtervalue"
      variant="outlined" placeholder="{{ 'COMMON.Search' | translate }}..." pAutoFocus [autofocus]="true" />
  </ng-template>
  <div class="my-4">
    <div class="loading justify-content-center" *ngIf="loading"><img src="./assets/images/loading.gif" alt=""
        style="width: 60px;"></div>
    <div *ngFor="let sidebar of filtered" class="px-4 py-2">
      <div class="filter-header"> {{sidebar.key}} </div>
      <a *ngFor="let item of sidebar.items" [routerLink]="item.url" (click)="searchvisible=false"
        class="px-3 py-2 flex gap-4 custom_search_filter-item">
        <span style="color:black;">
          <span class="" [innerHTML]="highlightMatch(item.name)"></span>
        </span>
      </a>
    </div>
    <div class="not-found justify-content-center" *ngIf="!loading">
      <div *ngIf="filtervalue&&filtered.length === 0">
        <img src="./assets/images/icons/not-found.svg" alt="">
        <p>{{ 'COMMON.NoDataFoundFor' | translate }}
          <span class="bold">
            "{{filtervalue}}"
          </span>
        </p>
      </div>
    </div>
  </div>
</p-dialog>