import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Activate, authenticationResponse, ExtandSubscription, Licence, ResetPassword, User } from "../models/user";
import { environment } from "src/environments/environment";
import * as signalR from "@microsoft/signalr"
import axios from 'axios';
import { Employee, EmployeeOneEntity } from "src/app/admin/employeemanagement/employee/allemployee/employee.model";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private readonly tokenKey: string = 'token';
  private readonly refreshtokenkey: string = 'refreshToken';
  private readonly refreshTokenExpiryTime: string = 'token-expiration';
  private readonly role = "role";
  Permissions: string[] = [];
  private notifications: Array<any> = [];
  private notificationSubject = new Subject<string>();
  private hubConnection!: signalR.HubConnection;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    const user: User =
    {
      userId: this.currentUserSubject.value.userId,
      imageUrl: this.currentUserSubject.value.imageUrl,
      profile: this.currentUserSubject.value.profile,
      role: this.getRoleName(),
      token: this.getToken(),
    }
    return user;
  }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('https://tenancy.bayanerp.com/Notifications', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => localStorage.getItem("token"),
      })
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));
    this.hubConnection.on('messages', (message, notification) => {
      var notify = JSON.stringify(notification);
      this.notificationSubject.next(notification);
      console.log("signalR notification " + notification);
      console.log(notify);
    });
  }

  stopConnection() {
    this.hubConnection.stop().catch(err => console.error(err.toString()));
  }

  getNotifications() {
    return null;
    // return this.http.get<any>(`${environment.tenancy_url}api/usernotifications/get`);
  }

  getNotificationEmittedValue() {
    return this.notifications;
  }

  getNotificationSubject() {
    return this.notificationSubject.asObservable();
  }


  //   var connection = new signalR.HubConnectionBuilder().withUrl("https://localhost:44362/Notifications", {
  //     skipNegotiation: true,
  //     transport: signalR.HttpTransportType.WebSockets,
  //     accessTokenFactory: () => 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImRmMGQyNGVmLWU2MjQtNDU0Yi1hZGQ0LTkxMGYzZDY4ZTliZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InNhZGVxYWJ1aGF0dGVtQGdtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiOTY3NzczMDYyMjEwIiwiZnVsbE5hbWUiOiJCYXlhbiBFcnBBZG1pbiIsIlRlbmFudCI6InJvb3QiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBZG1pbiIsImV4cCI6MTY4NTQ1MDE2MH0.ycLlKwpUvMuZZ29ZZeDHgxP70QbvpcCgk9048GWuM5I'
  //   }).build();
  // connection.on("messages", (message, notification) => {
  //   var notify = JSON.stringify(notification);
  //   console.log(notify);
  //   toastr.success(notification.title + " " + notification.message, {
  //     positionClass: "toast-bottom-full-width",
  //     containerId: "toast-bottom-full-width",
  //     progressBar: !0
  //   });

  //   console.log("signalR message" + message);
  //   console.log("signalR message" + notify.Message);
  // });

  // connection.start().then(function() {
  //     console.log("connection established.");
  //   }).catch(function(err) {
  //     return console.error(err.toString());
  //   });

  login(phoneNumber: string, password: string, tenantId: string) {
    this.logout();
    this.getIPAddress();
    return this.http
      .post<any>(`${environment.apiUrl}/tokens/create`, {
        // .post<any>(`${environment.tenancy_url}api/tokens`, {
        phoneNumber,
        password,
      }, { headers: new HttpHeaders({ tenantId }) }).pipe(
        // }).pipe(
        map((user) => {
          console.log(user.data);
          localStorage.setItem("currentUser", JSON.stringify(user.data));
          this.currentUserSubject.next(user);
          localStorage.setItem("imageUrl", user.data.profile.imageUrl);
          localStorage.setItem('username', user.data.profile.fullName);
          localStorage.setItem(this.role, user.data.profile.rolesPermissions.roles.map(r => r.name).toString());
          localStorage.setItem('branches', JSON.stringify(user.data.profile.branches));
          localStorage.setItem('branchId', user.data.profile.branches[0].branchId);
          return user;
        })
      );
  }

  addUser(user: User): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/identity/create', user);
  }

  getIPAddress() {
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        localStorage.setItem("ip", response.data.ip);
        console.log(response.data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }

  lang = localStorage.getItem("lang");

  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.refreshtokenkey);
    localStorage.removeItem(this.refreshTokenExpiryTime);
    localStorage.removeItem(this.role);
    localStorage.removeItem("userId");
    localStorage.removeItem("imageUrl");
    localStorage.removeItem("username");
    localStorage.removeItem("Permissions");
    localStorage.removeItem('branchId');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  async saveToken(authentication: authenticationResponse) {
    localStorage.setItem(this.tokenKey, authentication.token);
    localStorage.setItem(this.refreshtokenkey, authentication.refreshToken);
    localStorage.setItem(this.refreshTokenExpiryTime, authentication.refreshTokenExpiryTime);
    localStorage.setItem("userId", authentication.userId);
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  getRoleName() {
    return localStorage.getItem(this.role);
  }

  getFieldFromJWT(field: string): string {
    const token = localStorage.getItem(this.tokenKey);
    if (!token) { return ''; }
    const dataToken = JSON.parse(atob(token.split('.')[1]));
    return dataToken[field];
  }


  getRoleandpermission(userId: string) {
    return this.http.get<any>(`${environment.apiUrl}/users/getrolespermissions/${userId}`).pipe(
      map((rolepermission) => {
        localStorage.setItem(this.role, rolepermission.data.roles.map(r => r.name).toString());
        return rolepermission;
      })
    );
  }

  getCurrency() {
    return this.http.get<any>(`${environment.apiUrl}/v1/currency/getsettingcurrencies`).pipe(
      map((currency) => {
        console.log(currency.data);
        localStorage.setItem("salescurrency", localStorage.getItem("lang") == 'ar' ? currency.data.salesPriceCurrencyCode : currency.data.fSalesPriceCurrencyCode);
        localStorage.setItem("costcurrency", localStorage.getItem("lang") == 'ar' ? currency.data.inventoryCostCurrencyCode: currency.data.fInventoryCostCurrencyCode);
        localStorage.setItem("currencycode", localStorage.getItem("lang") == 'ar' ? currency.data.localCurrencyCode: currency.data.fLocalCurrencyCode);
      })
    );
  }

  // GetUserPermission(id: string) {
  //   return this.http.get<any>(`${environment.apiUrl}/roles/${id}/permissions`).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  GenerateRefreshToken(token: string, refreshToken: string) {
    console.log(token);
    console.log(refreshToken);
    return this.http
      .post<any>(`${environment.apiUrl}/tokens/refresh`, {
        token,
        refreshToken
      })
      .pipe(
        map((user) => {
          localStorage.setItem("currentUser", JSON.stringify(user.data));
          this.saveToken(user.data);
          this.currentUserSubject.next(user);
          localStorage.setItem("imageUrl", user.data.profile.imageUrl);
          localStorage.setItem('username', user.data.profile.fullName);
          localStorage.setItem(this.role, user.data.profile.rolesPermissions.roles.map(r => r.name).toString());
          localStorage.setItem('branches', JSON.stringify(user.data.profile.branches));
          localStorage.setItem('branchId', user.data.profile.branches[0].branchId);
          return user;
        }),
        catchError((error) => {
          if (error.status === 401) {
            this.logout();
          }
          return throwError(error);
        })
      );
  }

  ResendOtp() {
    return this.http.put<any>(`${environment.apiUrl}/identity/${localStorage.getItem("userId")}/resendactivation`, null);
  }

  Activate(activate: Activate) {
    return this.http.put<any>(`${environment.apiUrl}/identity/${localStorage.getItem("userId")}/activate`, activate);
  }

  ForgetPassword(phoneNumber: string) {
    return this.http.post<any>(`${environment.apiUrl}/identity/forgot-password`, phoneNumber);
  }

  ResetPassword(resetPassword: ResetPassword) {
    return this.http.post<any>(`${environment.apiUrl}/identity/reset-password`, resetPassword);
  }

  getDropDownCountris(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/v1/country/getdropdownlist`);
  }

  getDropDownCities(countryId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/v1/city/getdropdownlistbycountryid?CountryId=${countryId}`);
  }


  licence(licence: Licence) {
    return this.http.post<any>(`${environment.tenancy_url}api/tenant/subscriptions/licence`, licence);
  }

  ExtandSubscription(extandsubscription: ExtandSubscription) {
    return this.http.post<any>(`${environment.tenancy_url}api/tenant/subscriptions/extand`, extandsubscription);
  }

  GetSettingsFromApi() {
    return this.http.get<any>(`${environment.apiUrl}/v1/appsettings/getallsettings`).pipe(
      map((res) => {
        console.log(res);
        return res;
      })
    );
  }

  GetProjectSettingsFromApi() {
    return this.http.get<any>(`${environment.apiUrl}/v1/appsettings/getproject`).pipe(
      map((res) => {
        console.log(res);
        return res;

      })
    );
  }

  GetSalesmanSettingsFromApi() {
    return this.http.get<any>(`${environment.apiUrl}/v1/appsettings/getsalesman`).pipe(
      map((res) => {
        console.log(res);
        return res;

      })
    );
  }
  GetMarketerSettingsFromApi() {
    return this.http.get<any>(`${environment.apiUrl}/v1/appsettings/getmarketer`).pipe(
      map((res) => {
        console.log(res);
        return res;

      })
    );
  }




  addEmployee(employee: EmployeeOneEntity): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/v1/employee/createnew', employee);
  }

  getDropDownCountry(): any {
    return this.http.get(`${environment.apiUrl}/v1/country/getdropdownlist`);
  }

  getDropDownCity(id: string): any {
    return this.http.get(`${environment.apiUrl}/v1/city/getdropdownlistbycountryid?CountryId=${id}`);
  }
  getDropDownUser(): any {
    return this.http.get(`${environment.apiUrl}/users/getdropdownlist`);
  }
  getDropDownNationalityId(): any {
    return this.http.get(`${environment.apiUrl}/v1/nationality/getactivedropdownlist`);
  }

  getDropDownCourtesyTitleId(): any {
    return this.http.get(`${environment.apiUrl}/v1/courtesytitle/getactivedropdownlist`);
  }

  getDropDownPaymentMethodByType(type: string): any {
    return this.http.get(`${environment.apiUrl}/v1/paymentmethod/getdropdownlistbypaymenttype/${type}`);
  }

  getDropDownJopId(): any {
    return this.http.get(`${environment.apiUrl}/v1/job/getdropdownlist`);
  }

  getDropDownPositionId(): any {
    return this.http.get(`${environment.apiUrl}/v1/position/getdropdownlist`);
  }

  getAllDropDownDepartmentId(): any {
    return this.http.get(`${environment.apiUrl}/v1/department/getactivedropdownlist`);
  }
  getAllDropDownSectionId(id: string): any {
    return this.http.get(`${environment.apiUrl}/v1/section/getdropdownlist/${id}`);
  }
  getDropDownMaritalStatusId(): any {
    return this.http.get(`${environment.apiUrl}/v1/maritalstatus/getdropdownlist`);
  }
  getDropDownEducationDegree(): any {
    return this.http.get(`${environment.apiUrl}/v1/educationdegree/getdropdownlist`);
  }
  getDropDownSpecializationId(): any {
    return this.http.get(`${environment.apiUrl}/v1/specialization/getdropdownlist`);
  }


  getDropDownDocumenttype(): any {
    return this.http.get(`${environment.apiUrl}/v1/documenttype/getactivedropdownlist`);
  }
}

