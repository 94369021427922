<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <p-scrollPanel class="menu" [style]="{ width: '100%', height: listMaxHeight+'px' }" #scrollPanel
      [ngStyle]="{'max-height' : listMaxHeight+'px'}">
      <!-- [perfectScrollbar] -->
      <div class="sidebar-user-panel">
        <div class="user-panel">
          <div class=" image">
            <img src="{{userImg}}" routerLink="currentuser/profile" class="img-circle user-img-circle" alt="User Image"
              (error)="onImgError($event)" />
            <span>
              <span>{{userFullName | slice:0:15}}</span>
              <p class="profile-usertitle-job" matTooltip="{{userType}}" matTooltipPosition="above">
                {{userType | slice:0:22}} <span *ngIf="userType.length>22">...</span> </p>
            </span>
          </div>
          <p-autoComplete #autoComplete [(ngModel)]="selectedsidebar" [showEmptyMessage]="true"
            [suggestions]="filteredSidebar" class="sidebarsearch" (completeMethod)="filterSide($event)"
            (onSelect)="selected()" [minLength]="1" [style]="{'width':'95%'}" name="text" field="text"
            placeholder="{{'COMMON.GoTo' | translate}}">
            <ng-template let-side pTemplate="item">
              <a class="country-item" [routerLink]="side.value" style="color:black;">
                <span [innerHTML]="highlightMatch(side.text | translate)"></span>
              </a>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'height' : listMaxHeight+'px','max-width' : listMaxWidth+'px'}">
        <!-- <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img src="{{userImg}}" class="img-circle user-img-circle" alt="User Image" (error)="onImgError($event)" />
              <span>
                <span>{{userFullName | slice:0:15}}</span>
                <p class="profile-usertitle-job">{{userType | slice:0:22}}... </p>
              </span>
            </div>

            <p-autoComplete [(ngModel)]="selectedsidebar" [showEmptyMessage]="true" [suggestions]="filteredSidebar"
              class="sidebarsearch" (completeMethod)="filterSide($event)" (onSelect)="selected()" [minLength]="1"
              [style]="{'width':'95%'}" name="text" field="text" placeholder="{{'COMMON.GoTo' | translate}}">
              <ng-template let-side pTemplate="item">
                <div class="country-item">
                  <div>{{side.text | translate}}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </li> -->
        <!-- Top Most level menu -->
        <!-- <li class="sidebar-search">
          <p-autoComplete [(ngModel)]="selectedCountry" [showEmptyMessage]="true" [suggestions]="filteredCountries"
            class="sidebarsearch" (completeMethod)="filterSide($event)" (onSelect)="selected()" [minLength]="1"
            [style]="{'width':'100%'}" name="text" field="text" placeholder="{{'COMMON.GoTo' | translate}}">
            <ng-template let-side pTemplate="item">
              <div class="country-item">
                <div>{{side.text | translate}}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </li> -->
        <!-- [ngClass]="isActiveSubMenuItem(sidebarItem.moduleName) ? 'active' : ''" -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          [id]="sidebarItem.moduleName"
          [ngClass]="[sidebarItem.displayPermission == false && sidebarItem.groupTitle==true  ? 'hideitem' : '']"
          [ngClass]="sidebarItem.displayPermission == false ? 'hideitem' : ''" *ngFor="let sidebarItem of sidebarItems"
          [routerLinkActive]="sidebarItem.submenu.length != 0 ? '': 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null"
            *ngIf="!sidebarItem.groupTitle&&sidebarItem.displayPermission===true" [ngClass]="[sidebarItem.class]"
            (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
            <!-- <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i> -->
            <img class="menu-img" src="assets/images/icons/{{sidebarItem.iconPath}}" alt="">
            <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
            <!-- <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span> -->
          </a>
          <!-- [ngClass]="isActiveSubMenuItem(sidebarSubItem.moduleName) ? 'activeSub' : ''" -->
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''" [id]="sidebarSubItem.moduleName"
              [ngClass]="sidebarSubItem.displayPermission == false ? 'hideitem' : ''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)"
                *ngIf="sidebarSubItem.displayPermission===true" [ngClass]="[sidebarSubItem.class]">
                <!-- <i [ngClass]="[sidebarSubItem.iconType]">{{sidebarSubItem.icon}}</i> -->
                <img class="menu-img" src="assets/images/icons/{{sidebarSubItem.iconPath}}" alt="">
                <span>{{sidebarSubItem.title | translate}}</span>
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [ngClass]="sidebarSubsubItem.displayPermission == false ? 'hideitem' : ''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    *ngIf="sidebarSubsubItem.displayPermission===true" [ngClass]="[sidebarSubsubItem.class]">
                    <span>{{sidebarSubsubItem.title | translate}}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="sidebar-logout">
          <a class="" (click)="logout()" class="menu-top">
            <i class="material-icons-two-tone">power_settings_new</i>
            <span class="hide-menu"> {{ 'COMMON.Logout' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </p-scrollPanel>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>